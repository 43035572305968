import { createStore, applyMiddleware, compose } from 'redux'
// import reducers from './reducers'
import createRootReducer from './reducers'
import thunk from 'redux-thunk';
import createHelpers from './store/createHelpers';
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import gql from  'graphql-tag';

export default function configureStore(initialState = {},config) {
    const helpers = createHelpers({...config,gql});
    const { apolloClient } = config;
    const middlewares = [
        thunk.withExtraArgument(helpers),
        loadingBarMiddleware()
    ]

    const enhancers = [
        applyMiddleware(...middlewares)
    ]

    const rootReducer = createRootReducer({
        apolloClient,
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer
        , initialState
        , composeEnhancers(...enhancers)
    )

    // Extensions
    //store.runSaga = sagaMiddleware.run
    store.asyncReducers = {} // Async reducer registry

    return store
}
