import gql from 'graphql-tag';

import {
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAILED,
    DELETE_PHOTOS_REQUEST,
    DELETE_PHOTOS_SUCCESS,
    DELETE_PHOTOS_FAILED,

} from "../types/other";

const getCities = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query{
                    GetCitiesOfTheCountry(country_id:102) {
                        id
                        name
               			province_id
                    }
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_CITY_REQUEST}
        }

        function success(result) {
            return {
                type: GET_CITY_SUCCESS,
                payload: result.GetCitiesOfTheCountry,
            }
        }

        function failure(error) {
            return {type: GET_CITY_FAILED, error: {error}}
        }
    }
}


const deletePhoto = (client, photoId, photoable_id, type) => {
    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
                  mutation  {
                    deletePhotosModel(photo_id: ${photoId}, photoable_id: ${photoable_id}, photoable_type: "${type}")
                    }   
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: DELETE_PHOTOS_REQUEST}
        }

        function success(result) {
            return {
                type: DELETE_PHOTOS_SUCCESS,
                payload: result.deletePhotosModel,
            }
        }

        function failure(error) {
            return {type: DELETE_PHOTOS_FAILED, error: {error}}
        }
    }
}
const makeCover = (client, photoId, photoable_id, type) => {
    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
                  mutation  {
                    makePhotoAsCover(photo_id: ${photoId}, photoable_id: ${photoable_id}, photoable_type: "${type}")
                    }   
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: DELETE_PHOTOS_REQUEST}
        }

        function success(result) {
            return {
                type: DELETE_PHOTOS_SUCCESS,
                payload: result.deletePhotosModel,
            }
        }

        function failure(error) {
            return {type: DELETE_PHOTOS_FAILED, error: {error}}
        }
    }
}
const getExchangeRate = (client) => {

    return client.query({
        query: gql`
                  query{
                  GetExchangeRate
                  }
          `
    }).then(result => {
        return result.data.GetExchangeRate
    }).catch(error => {
        return error
    });


}

export default {
    getCities,
    deletePhoto,
    makeCover,
    getExchangeRate,
}