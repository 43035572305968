import React from "react";
import ReactTable from "react-table";


export default class ReactTableCustom extends React.Component {


    onPageChange(pageIndex) {
        let newAccess = Object.assign({}, this.props.pagination);
        pageIndex++;
        newAccess.page = pageIndex;
        this.props.fetchMore(newAccess)
    }


    onPageSizeChange(pageSize, pageIndex) {

        this.props.pagination.per_page = pageSize
        this.props.fetchMore({per_page: pageSize, page: pageIndex})

    }

    onFilteredChange(column, value) {

        console.log(column, value)

    }

    render() {

        return <ReactTable
            data={this.props.data}
            manual
            filterable
            columns={this.props.columns}
            pageSize={this.props.pagination && this.props.pagination.per_page}
            pages={this.props.pagination && this.props.pagination.last_page}
            page={this.props.pagination && this.props.pagination.page}

            onPageChange={(pageIndex) => {
                this.onPageChange(pageIndex)
            }}
            onPageSizeChange={(pageSize, pageIndex) => {
                this.onPageSizeChange(pageSize, pageIndex)
            }}
            onFilteredChange={(column, value) => {
                this.props.onFilteredChange(column, value)
            }}
            loading={this.props.loading}
            showPaginationBottom={true}
            className="-striped -highlight"
        />
    }

}