import {
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILED,
    GET_PAYOUT_REQUEST,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_FAILED

} from "../types/Transactions";

const initialState = {
    data: [],
    payout_requests: [],
    pagination: {
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 15,
        from: 0,
        to: 0
    },
    loading: true,
    error: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTIONS_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                pagination: action.pagination
            }
        }
        case GET_TRANSACTIONS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
 case GET_PAYOUT_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_PAYOUT_SUCCESS: {
            return {
                ...state,
                loading: false,
                payout_requests: action.payload,
             }
        }
        case GET_PAYOUT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }

        default:
            return state

    }
}