import Dashboard from "views/Dashboard/Dashboard.js";
import UserInfo from "views/admin/UserInfo";
import UserList from "views/admin/UserList";
import AddUser from "views/admin/Add";

//Hotel Pages
import HotelList from "views/Hotels/HotelList";
import HotelEdit from "views/Hotels/Edit";
import HotelAdd from "views/Hotels/Add";
import HotelPhoto from "views/Hotels/Photos";
import HotelFacilities from "views/Hotels/Facilities";
import HotelPackage from "views/Hotels/packages/Packagelist";
import HotelBookHistory from "views/Hotels/BookedHistory";
import HotelRooms from "views/Hotels/packages/Rooms";
import HotelRoomEdit from "views/Hotels/packages/Edit";
import HotelRoomAdd from "views/Hotels/packages/Add";
import HotelRoomPhotos from "views/Hotels/packages/Photos";
import HotelRoomFacilitites from "views/Hotels/packages/Facilities";
import HotelRoomPriciing from "views/Hotels/Pricing";

//Transactions
import Transactions from "views/Transaction/Transactions";
import PayoutRequests from "views/Transaction/payoutsRequests";
import PayoutRequest from "views/Transaction/Payout";

//Requests
import HotelRequests from "views/Requests/Hotel/Requests";
import HotelRequestsHistory from "views/Requests/Hotel/History";
import HotelRequestDetails from "views/Requests/Hotel/Details";

//User
import Wallet from "views/User/Wallet";
import UserProfile from "views/User/UserProfile";
import UserLogs from "views/User/Logs";


//PromoCode
import PromoCodes from "views/PromoCodes/PromoCodeList";
import AddPromoCodes from "views/PromoCodes/Add.js";
import AffiliateLink from "views/PromoCodes/AffiliateLinks";
import Edit from "views/PromoCodes/Edit";
import TransactionPromoCode from "views/PromoCodes/Transactions";


//icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Group";
import HotelIcon from "@material-ui/icons/Hotel";
import PromoIcon from "@material-ui/icons/LocalOffer";
import AffiliateIcon from "@material-ui/icons/Link";
import TransactionsIcon from "@material-ui/icons/List";
import PaymentIcon from "@material-ui/icons/Payment";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import RequestsIcon from "@material-ui/icons/Book";
import LogsIcon from "@material-ui/icons/ListAlt";

var dashRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin"
    },
    {
        collapse: true,
        name: "Requests",
        access: "requests",
        rtlName: "درخواست ها",
        state: "formsCollapse",
        icon: RequestsIcon,
        views: [
            {
                path: "/hotel-requests",
                name: "Hotel Requests",
                rtlName: "درخواست های هتل",
                mini: "HR",
                access: "hotel_requests",
                rtlMini: "ه",
                component: HotelRequests,
                layout: "/admin"
            },
            {
                path: "/hotel-request-history",
                name: "Hotel Requests History",
                rtlName: "درخواست های هتل",
                mini: "HR",
                access: "hotel_requests",
                rtlMini: "ه",
                component: HotelRequestsHistory,
                layout: "/admin"
            },
        ]
    },

    {
        sub: true,
        path: "/hotel-request-details/:token",
        name: "Hotel Requests Details",
        rtlName: "جزییات درخواست",
        access: "hotel_requests",
        component: HotelRequestDetails,
        layout: "/admin",
    },
    {
        path: "/payout-requests",
        name: "Payout Requests",
        access: "clear_payout",
        rtlName: "درخواست های تسویه",
        icon: PaymentIcon,
        component: PayoutRequests,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/payout-request/:panel_user_id",
        name: "Payout Request",
        rtlName: "درخواست تسویه",
        icon: PaymentIcon,
        component: PayoutRequest,
        layout: "/admin",
    },
    {
        path: "/transactions",
        name: "Transactions",
        rtlName: "تراکنش ها",
        access: "transaction",
        icon: TransactionsIcon,
        component: Transactions,
        layout: "/admin",
    },

    {
        path: "/wallet",
        name: "Wallet",
        rtlName: "کیف پول",
        icon: WalletIcon,
        access: "wallet",
        component: Wallet,
        layout: "/admin",

    },
    {

        path: "/users",
        name: "Users",
        rtlName: "کاربران",
        access: "user_permission",
        icon: PersonIcon,
        component: UserList,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/user/:user_id",
        name: "User Information",
        rtlName: "اطلاعات کاربر",
        access: "user_permission",
        mini: "UI",
        rtlMini: "ک",
        component: UserInfo,
        layout: "/admin"
    },
    {
        sub: true,
        path: "/add-new-user",
        name: "Add User",
        rtlName: "افزودن کاربر",
        access: "user_permission",
        mini: "UI",
        rtlMini: "ک",
        component: AddUser,
        layout: "/admin"
    },
    {
        path: "/hotels",
        name: "Hotels",
        access: "hotel_permission",
        rtlName: "هتل ها",
        icon: HotelIcon,
        component: HotelList,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/hotel/edit/:hotel_id",
        name: "Hotels",
        access: "hotel_permission",
        rtlName: "ویرایش هتل",
        icon: HotelIcon,
        component: HotelEdit,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/hotel/add-new-hotel",
        name: "Hotels",
        access: "add_hotel_permission",
        rtlName: "ویرایش هتل",
        icon: HotelIcon,
        component: HotelAdd,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/hotel/photos/:hotel_id",
        name: "Hotels",
        access: "hotel_permission",
        rtlName: "تصاویر هتل",
        icon: HotelIcon,
        component: HotelPhoto,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/hotel/facilities/:hotel_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        access: "hotel_permission",
        icon: HotelIcon,
        component: HotelFacilities,
        layout: "/admin",
        views: []
    },
    {
        sub: true,
        path: "/hotel/book-history/:hotel_id",
        name: "Hotels",
        rtlName: "تاریخچه رزرو",
        access: "hotel_permission",
        icon: HotelIcon,
        component: HotelBookHistory,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/packages/:hotel_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        access: "hotel_permission",
        icon: HotelIcon,
        component: HotelPackage,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/rooms/:package_id/:hotel_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        icon: HotelIcon,
        access: "hotel_permission",
        component: HotelRooms,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/room-edit/:package_id/:hotel_id/:room_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        icon: HotelIcon,
        component: HotelRoomEdit,
        access: "hotel_permission",
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/add-room/:hotel_id/:package_id",
        name: "Hotels",
        rtlName: "افزودن اتاق",
        icon: HotelIcon,
        component: HotelRoomAdd,
        access: "hotel_permission",
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/room-photos/:package_id/:hotel_id/:room_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        access: "hotel_permission",
        icon: HotelIcon,
        component: HotelRoomPhotos,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/room-facilities/:package_id/:hotel_id/:room_id",
        name: "Hotels",
        rtlName: "تصاویر هتل",
        access: "hotel_permission",
        icon: HotelIcon,
        component: HotelRoomFacilitites,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/hotel/room-pricing/:hotel_id",
        name: "Hotels",
        rtlName: "قیمت گذاری اتاق ها",
        icon: HotelIcon,
        access: "room_availability",
        component: HotelRoomPriciing,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/user-profile",
        name: "Profile",
        rtlName: "پر.فایل",
        icon: HotelIcon,
        access: "profile",
        component: UserProfile,
        layout: "/admin",
    },

    {
        path: "/promo-codes",
        name: "Promo Codes",
        rtlName: "کد تخفیف",
        icon: PromoIcon,
        access: "get_promo_code",
        component: PromoCodes,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/add-promo-code",
        name: "Promo Codes",
        rtlName: "کد تخفیف",
        icon: PromoIcon,
        access: "update_promo_code",
        component: AddPromoCodes,
        layout: "/admin",
    },
    {
        sub: true,
        path: "/edit-promo-code/:promo_code_id",
        name: "Edit Promo Code",
        rtlName: "کد تخفیف",
        icon: PromoIcon,
        access: "edit_promo_code_commissions",
        component: Edit,
        layout: "/admin",
    },

    {
        sub: true,
        path: "/promo-code-transactions/:promo_code_id",
        name: "Promo Code Transactions",
        rtlName: "کد تخفیف",
        access: "get_promo_code",
        icon: PromoIcon,
        component: TransactionPromoCode,
        layout: "/admin",
    },

    {

        path: "/affiliate-links",
        name: "Affiliated Links",
        rtlName: "لینک های مشارکت",
        icon: AffiliateIcon,
        access: "generate_affiliate_links",
        component: AffiliateLink,
        layout: "/admin",
    },

    {
        path: "/user-logs",
        name: "User Logs",
        rtlName: "پر.فایل",
        icon: LogsIcon,
        access: "access_logs",
        component: UserLogs,
        layout: "/admin",
    },
];
export default dashRoutes;
