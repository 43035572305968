import React, {useEffect, useRef} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../../config";


import Tooltip from "@material-ui/core/Tooltip";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import OthersAction from "actions/Others";

// core components
import CardFooter from "components/Card/CardFooter.js";
import Photo from "@material-ui/icons/Photo";
import Place from "@material-ui/icons/Place";
import Remove from "@material-ui/icons/Delete";
import Language from "@material-ui/icons/Language";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";

const styles = {
    ...stylesAlert,
    ...hoverCardStyle,
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
        textAlign: "center"
    },
    cardCategory: {
        fontSize: "14px",
        paddingTop: "10px",
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardProductDesciprion: {
        textAlign: "center",
    },
    stats: {
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    productStats: {
        paddingTop: "7px",
        paddingBottom: "7px",
        margin: "0"
    },
    successText: {},
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: "17px",
        height: "17px"
    },
    price: {
        color: "inherit",
        "& h4": {
            marginBottom: "0px",
            marginTop: "0px"
        }
    }
};

const useStyles = makeStyles(styles);

function Index(props) {

    const [alert, setAlert] = React.useState(null);
    const [roomData, setRoomData] = React.useState({});
    const [savingPhoto, setSavingPhoto] = React.useState(false);
    const [packageid, setPackageId] = React.useState(0);
    const [roomId, setRoomId] = React.useState(0);

    const removePhoto = useRef();

    const {dispatch, client} = props;
    const classes = useStyles();
    useEffect(() => {
        fetchImages(dispatch, client)


    }, props.loading);

    const fetchImages = (dispatch, client) => {
        // dispatch(HotelAction.getHotel(client, id));
        let id = props.match.params.hotel_id;
        let room_id = props.match.params.room_id;
        let package_id = props.match.params.package_id;
        setPackageId(package_id)
        setRoomId(room_id)
        dispatch(HotelAction.getHotel(client, id)).then(res => {

            (res.payload.rooms).map((e, i) => {

                if (e.id == room_id) {

                    setRoomData(e)

                }
            })

        });
    }

    const warningWithConfirmAndCancelMessage = (photoId, photoable_id, type) => {

        console.log(photoId)
        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Are you sure?"
                onConfirm={() => successDelete(photoId, photoable_id, type)}
                onCancel={() => cancelDetele()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        )
    };
    const successDelete = (photoId, photoable_id, type) => {
        dispatch(OthersAction.deletePhoto(client, photoId, photoable_id, type));

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your imaginary file has been deleted.
            </SweetAlert>
        );
    };
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Uploaded!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your imaginary file has been uploaded.
            </SweetAlert>
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{display: "block", marginTop: "-100px"}}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your imaginary file is safe :)
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        let id = props.match.params.hotel_id;
        client.cache.reset()
        fetchImages(dispatch, client, id)
    };

    const {data} = props;
    const submitPlacePhoto = (e) => {
        e.preventDefault()
        const frmUpload = document.getElementById('frmUpload')
        let room_id = props.match.params.room_id;
        setSavingPhoto(true)

        let formData = new FormData(e.target);
        formData.append('room_id', room_id)

        console.log(formData)
        let token = localStorage.getItem('access_token');
        fetch(apiUrl + 'api/room/photo', {
            method: 'POST', body: formData, headers: {
                authorization: token ? `Bearer ${token}` : null
            }
        }).then(res => {
            setSavingPhoto(false)

            console.log(res)
            if (res.ok) {
                successUpload()
                frmUpload.reset()
                removePhoto.current.handleRemove()
            }
        }).catch(err => {
            console.log(err)
            setSavingPhoto(false)

        })

    }

    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <Photo/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}><a target={"_blank"}
                                                                 href={"/admin/hotel/edit/" + data.id}>{data.name} Hotel
                            Photos</a></h4>
                        <h4 className={classes.cardIconTitle}> {roomData.name}</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={(e) => submitPlacePhoto(e)} id="frmUpload">

                            <GridContainer>
                                <GridItem xs={12} sm={4} md={2}>

                                </GridItem>
                                <GridItem xs={12} sm={4} md={3}>
                                    <ImageUpload
                                        name={"file"}
                                        ref={removePhoto}
                                        addButtonProps={{
                                            color: "info",
                                            round: true
                                        }}
                                        changeButtonProps={{
                                            color: "info",
                                            round: true
                                        }}
                                        removeButtonProps={{
                                            color: "danger",
                                            round: true
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Photo Alt Text"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "meta_data[caption]",
                                            defaultValue: ""
                                        }}
                                    />

                                </GridItem>

                                <GridItem xs={12} sm={12} md={1}>
                                    {
                                        savingPhoto ? <Button type={"button"} color="warning">Loading...</Button>
                                            :
                                            <Button type={"submit"}
                                                    color="primary">Save</Button>


                                    }
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <a href={"/admin/hotel/room-facilities/" + packageid + "/" + data.id + "/" + roomId}>

                                        <Button type={"button"} color="info">Next Step</Button>

                                    </a>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
            <GridContainer>
                {
                    (roomData.photos || []).map((e, i) => {
                        console.log(e.id)
                        return <GridItem xs={12} sm={12} md={3}>
                            <Card product className={classes.cardHover}>
                                <CardHeader image className={classes.cardHeaderHover}>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        <img src={apiUrl + e.photo}
                                             style={{minHeight: 300, width: "100%", objectFit: "cover"}}
                                             alt={e.meta_data && e.meta_data.caption}/>
                                    </a>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardHoverUnder}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Remove"
                                            placement="bottom"
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <Button color="danger"
                                                    onClick={b => warningWithConfirmAndCancelMessage(e.id, roomData.id, 'room')}
                                                    simple
                                                    justIcon>
                                                <Remove className={classes.underChartIcons}/>
                                            </Button>
                                        </Tooltip>

                                        <Tooltip
                                            id="tooltip-top"
                                            title="Edit"
                                            placement="bottom"
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <Button color="warning" simple justIcon>
                                                <Edit className={classes.underChartIcons}/>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <h4 className={classes.cardProductTitle}>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            {e.meta_data && e.meta_data.caption}
                                        </a>
                                    </h4>
                                </CardBody>

                            </Card>
                        </GridItem>

                    })
                }
            </GridContainer>

        </GridContainer>
    )
        ;
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)