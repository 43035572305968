/*eslint-disable*/
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import OthersAction from "actions/Others";
import Utility from "Utils/helper.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const {fluid, white, rtlActive} = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
            [" " + classes.whiteColor]: white
        });
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    });

    const [rate, setRate] = React.useState(0);

    const {dispatch, client} = props;

    useEffect(() => {
        if (client)
            OthersAction.getExchangeRate(client).then(res => {
                setRate(res)
            });
    }, props.loading);


    return (
        <footer className={classes.footer}>
            <div className={container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="/" className={block}>
                                {rtlActive ? "الصفحة الرئيسية" : "Home"}
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="https://www.iranroute.com" className={block}>
                                {rtlActive ? "شركة" : "Company"}
                            </a>
                        </ListItem>

                        <ListItem className={classes.inlineBlock}>
                            <a href="https://blog.iranroute.com" className={block}>
                                {rtlActive ? "مدونة" : "Blog"}
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="#" className={block}>
                                {rtlActive ? "" : "Euro Rate (BUY): " + Utility.rialFormatter.format(rate)}
                            </a>
                        </ListItem>
                    </List>
                </div>
                <p className={classes.right}>
                    &copy; {1900 + new Date().getYear()}{" "}
                    <a
                        href="https://www.iranroute.com"
                        className={anchor}
                        target="_blank"
                    >
                        {rtlActive ? "توقيت الإبداعية" : "IranRoute"}
                    </a>
                    {rtlActive
                        ? ", مصنوعة مع الحب لشبكة الإنترنت أفضل"
                        : ", All you need to improve your business"}
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};
