import React, {useEffect} from "react";
import cx from "classnames";
import {Switch, Route, Redirect} from "react-router-dom";
// creates a beautiful scrollbar

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import * as jQuery from 'jquery'
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/User/UserAction";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
    global.jQuery = jQuery;
    global.$ = jQuery;
    const {...rest} = props;
    // states and functions
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [miniActive, setMiniActive] = React.useState(false);
    const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
    const [color, setColor] = React.useState("blue");
    const [bgColor, setBgColor] = React.useState("white");
    // const [hasImage, setHasImage] = React.useState(true);
    const [fixedClasses, setFixedClasses] = React.useState("dropdown");
    const [logo, setLogo] = React.useState(require("assets/img/logo.png"));
    // styles
    const classes = useStyles();
    const mainPanelClasses =
        classes.mainPanel +
        " " +
        cx({
            [classes.mainPanelSidebarMini]: miniActive
        });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
    React.useEffect(() => {

        window.addEventListener("resize", resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {

            window.removeEventListener("resize", resizeFunction);
        };
    });


    useEffect(() => {
        props.dispatch(UserAction.GetUserDetails(props.client));

    }, props.loading_main);


    // functions for changeing the states from components
    const handleImageClick = image => {
        setImage(image);
    };
    const handleColorClick = color => {
        setColor(color);
    };
    const handleBgColorClick = bgColor => {
        switch (bgColor) {
            // case "white":
            //     setLogo(require("assets/img/logo.svg"));
            //     break;
            // default:
            //     setLogo(require("assets/img/logo.png"));
            //     break;
        }
        // setLogo(require("assets/img/logo.png"));
        setBgColor(bgColor);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };
    const getActiveRoute = routes => {
        let activeRoute = "";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const childProps = {
        client: props.client,
        dispatch: props.dispatch,
        history: props.history,
    };

    const {user_details} = props

    const hasPermission = (permission) => {

        let has = user_details.roles.filter(e => {
            if (e.role == permission) {
                console.log(e.role, permission)
                return e

            }
        })

        if (has.length > 0)
            return true
        else
            return false


    };

    const getRoutes = routes => {

        return routes.map((prop, key) => {

            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                if (user_details.type == "admin" || hasPermission(prop.access))
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            render={props => <prop.component {...props} user_details={user_details} {...childProps}/>}
                            key={key}
                        />
                    );
            } else {
                return null;
            }
        });
    };
    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    localStorage.getItem('access_token') === null
    &&
    props.history.push({
        pathname: '/auth'
    })

    if (props.LoginError) {

        localStorage.removeItem("access_token")
        props.history.push({
            pathname: '/auth'
        })
    }


    if (props.loading_main)
        return "Loading..."
    return (

        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoText={"Iran Route"}
                logo={logo}
                user_details={user_details}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                bgColor={bgColor}
                miniActive={miniActive}
                {...rest}
                {...childProps}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
                <AdminNavbar
                    sidebarMinimize={sidebarMinimize.bind(this)}
                    miniActive={miniActive}
                    brandText={getActiveRoute(routes)}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                    history={props.history}
                />
                {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from="/admin" to="/admin/dashboard"/>
                            </Switch>
                        </div>
                    </div>
                ) : (
                    <div className={classes.map}>
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="/admin" to="/admin/dashboard"/>
                        </Switch>
                    </div>
                )}
                {getRoute() ? <Footer {...childProps} fluid/> : null}

            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user_details: state.user.user_details,
    loading_main: state.user.loading_main,
    LoginError: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)