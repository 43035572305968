import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Photo from "@material-ui/icons/Photo";
import Facilities from "@material-ui/icons/RoomService";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../../config";
import Room from "@material-ui/icons/Home";
import Remove from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

const styles = {
    ...stylesAlert,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {
    const [alert, setAlert] = React.useState(null);
    const [editId, setEditId] = React.useState(0);
    const [hotel_id, setHotelId] = React.useState(0);
    const [package_id, setPackageId] = React.useState(0);
    const [packageName, setPakckageName] = React.useState("");


    const {dispatch, client} = props;
    const classes = useStyles();

    const warningWithConfirmAndCancelMessage = (id) => {

        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Are you sure?"
                onConfirm={() => successDelete(id)}
                onCancel={() => cancelDetele()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        )
    };
    const successDelete = (id) => {
        deleteItem(id);

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >Your item has been deleted.</SweetAlert>
        );
    };
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Uploaded!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item has been saved.
            </SweetAlert>
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{display: "block", marginTop: "-100px"}}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item is safe :)
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        client.cache.reset()
        fetchData()
    };
    useEffect(() => {
        fetchData();

    }, props.loading);


    const fetchData = () => {
        let id = props.match.params.hotel_id;
        let package_id = props.match.params.package_id;
        setHotelId(id);
        setPackageId(package_id);
        client.cache.reset()
        dispatch(HotelAction.getHotel(client, id));


    }


    const deleteItem = (id) => {

        const {client} = props;

        dispatch(HotelAction.DeleteRoom(client, id))
    }
    const renderRooms = () => {
        let package_id = props.match.params.package_id;

        if (packageName == "")
            (data.RoomPackage || []).map((e) => {
                if (e.id == package_id) {
                    setPakckageName(e.name)

                }

            })


        let rooms = (data.rooms || []).filter((e) => {
            return e.room_package_id == package_id

        })

        return rooms

    }

    const {data} = props;
    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Button href="#" className={classes.title} style={{fontSize: "1.2rem"}} color="transparent">
                    Room Type of {packageName} | {data.name}
                </Button>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Room/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Hotel Room Packages</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={2}>
                                <a
                                   href={"/admin/hotel/add-room/" + hotel_id + "/" + package_id}><Button
                                    color="info">Add
                                    New
                                    Room Package</Button></a>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={renderRooms()}
                            filterable
                            pageSize={renderRooms().length}
                            columns={[

                                {
                                    Header: "Id",
                                    sortable: false,
                                    accessor: "id"
                                },

                                {
                                    Header: "Name",
                                    sortable: false,
                                    accessor: "name"
                                },

                                {
                                    Header: "Capacity",
                                    sortable: false,
                                    accessor: "capacity"
                                },
                                {
                                    Header: "Price",
                                    sortable: false,
                                    accessor: "price_full_board"
                                },

                                {
                                    Header: "Discount",
                                    sortable: false,
                                    accessor: "price_discount"
                                },

                                {
                                    Header: "Actions",
                                    accessor: "id",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">
                                            <a
                                                href={"/admin/hotel/room-edit/" + row.row._original.room_package_id + "/" + row.row._original.accommodation_id + "/" + row.value}

                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Edit"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="warning"
                                                        className="edit"
                                                    >


                                                        <Edit/>
                                                    </Button>
                                                </Tooltip>
                                            </a>


                                            {" "}
                                            <a
                                                href={"/admin/hotel/room-photos/" + row.row._original.room_package_id + "/" + row.row._original.accommodation_id + "/" + row.value}

                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Room Photos"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="info"
                                                        className="edit"
                                                    >


                                                        <Photo/>
                                                    </Button>
                                                </Tooltip>
                                            </a>


                                            {" "}
                                            <a
                                                href={"/admin/hotel/room-facilities/" + row.row._original.room_package_id + "/" + row.row._original.accommodation_id + "/" + row.value}
                                             >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Room Facilities"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="primary"
                                                        className="edit"
                                                    >


                                                        <Facilities/>
                                                    </Button>
                                                </Tooltip>

                                            </a>
                                            {" "}

                                            <Tooltip
                                                id="tooltip-top"
                                                title="Remove"
                                                placement="bottom"
                                                classes={{tooltip: classes.tooltip}}
                                            >
                                                <Button color="danger"
                                                        onClick={b => warningWithConfirmAndCancelMessage(row.value)}
                                                        simple
                                                        justIcon>
                                                    <Remove className={classes.underChartIcons}/>
                                                </Button>
                                            </Tooltip>

                                            {" "}

                                        </div>
                                    )
                                },
                            ]}

                            loading={props.loading}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                        />
                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)