import React, {Fragment, useEffect} from "react";
// react component for creating dynamic tables
import Check from "@material-ui/icons/Check";
import style from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Select from 'react-select';

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import Assignment from "@material-ui/icons/Assignment";
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Photo from "@material-ui/core/SvgIcon/SvgIcon";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
    ...style,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles({
    ...stylesAlert, ...styles
});

function Index(props) {
    let [selectedFacilities, setSelectedFacilities] = React.useState([]);

    const [init, setInit] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState("");
    const [facilitiesItems, setFacilitiesItems] = React.useState([]);
    const [load, isLoad] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const {dispatch, client} = props;
    const classes = useStyles();
    useEffect(() => {
        fetchData();

    }, props.loading);

    const fetchData = () => {
        let id = props.match.params.hotel_id;
        client.cache.reset()
        dispatch(HotelAction.getHotel(client, id));

        dispatch(HotelAction.getFacilities(client));

    }

    const {facilities, data} = props;


    useEffect(() => {
        if (data.facilities && !init) {
            setSelected()

        }

        if (!props.loading)
            facilities.map(e => {
                facilitiesItems.push({value: e.id, label: e.name})
            })
    }, null);


    const setSelected = () => {

        let services = data.facilities.map(e => {

            selectedFacilities.push({
                facility_id: parseInt(e.id),
                is_highlight: e.is_highlight
            })

        });


        setInit(true)

    }
    const submit = () => {
        let {client} = props;
        let id = props.match.params.hotel_id;

        client.cache.reset()
        isLoad(true)

        dispatch(HotelAction.updateFacility(client, id, selectedFacilities)).then(res => {
            isLoad(false)

            fetchData();
            successUpload()
        });


        return true;
    }
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Updated!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Facility Updated.
            </SweetAlert>
        );
    };

    const hideAlert = () => {
        setAlert(null);
    };
    const onCheck = (id, is_highlight) => {

        let is_in = false;
        let newSelected = selectedFacilities.filter(function (c) {
            if (c.facility_id == id) {
                if (!is_highlight) {
                    is_in = true;
                }
                if (is_highlight && c.is_highlight) {
                    is_highlight = false;
                }


            }


            return c.facility_id !== id
        })

        if (!is_in)
            newSelected.push({
                facility_id: parseInt(id),
                is_highlight: is_highlight
            })

        selectedFacilities = newSelected

    }
    const removeFacility = (id) => {

        let newFacilities = facilitiesItems
        facilities.map(e => {
            if (id == e.id)
                newFacilities.push({value: e.id, label: e.name})
        })
        setFacilitiesItems(newFacilities)


        let newSelected = selectedFacilities.filter(function (c) {
            return c.facility_id != id
        })

        setSelectedFacilities(newSelected)

    }

    const addFacility = (id, is_highlight) => {

        let newFacilitiesItems = facilitiesItems.filter(e => {
            return e.value != id
        })
        setSelectedItem("")
        setFacilitiesItems(newFacilitiesItems)

        let newSelected = selectedFacilities

        newSelected = [
            ...newSelected,
            {
                facility_id: parseInt(id),
                is_highlight: is_highlight
            }
        ]

        setSelectedFacilities(newSelected)
    }
    const renderChecks = (item) => {

        let is_facility = false;
        let is_highlight = false;
        const facility = (data.facilities || []).map((e, i) => {


            if (item == e.id) {
                is_highlight = e.is_highlight
                is_facility = true
            }
        });

        return <div>
            <FormControlLabel
                control={
                    <Checkbox
                        onClick={(e) => onCheck(item, false)}
                        checkedIcon={
                            <Check className={classes.checkedIcon}/>
                        }
                        icon={<Check className={classes.uncheckedIcon}/>}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                        inputProps={{
                            name: "facility",
                        }}
                        defaultChecked={is_facility}

                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                label="Facility"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onClick={() => onCheck(item, true)}
                        checkedIcon={
                            <Check className={classes.checkedIcon}/>
                        }
                        icon={<Check className={classes.uncheckedIcon}/>}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                        inputProps={{
                            name: "highlight",
                        }}
                        defaultChecked={is_highlight}

                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                label="Highlight"
            />
        </div>
    }

    const handleChange = selectedOption => {


        setSelectedItem(selectedOption)
    };

    const renderItems = () => {

        return selectedFacilities.map((e, i) => {

            return facilities.map(j => {

                if (j.id === e.facility_id) {
                    return <GridItem md={3}>
                        <Card>
                            <CardHeader  color="primary" icon>
                                <CardIcon color="rose"><img width="40" src={apiUrl + j.photo}/></CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    {j.name} ({e.is_highlight ? "Highlight" : "Facility"})
                                </h4>

                            </CardHeader>
                            <CardBody>

                                <Button
                                    onClick={() => removeFacility(j.id)} color="danger">Remove</Button>
                            </CardBody>

                        </Card>

                    </GridItem>
                }

            })


        })

    };


    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Button href="#" className={classes.title} style={{fontSize: "1.2rem"}} color="transparent">
                    Facilities of {data.name}
                </Button>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Hotel Facilities {"        "}
                        </h4>

                    </CardHeader>

                    {
                        !props.loading ? <Fragment>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={1}>

                                        <h4 className={classes.cardIconTitle}> Facilities {"        "}
                                        </h4>
                                    </GridItem>


                                    <GridItem xs={4}>

                                        <Select
                                            value={selectedItem}
                                            onChange={handleChange}
                                            options={facilitiesItems}
                                        />

                                    </GridItem>


                                    <GridItem xs={2}>
                                        <Button
                                            onClick={() => addFacility(selectedItem.value, false)} color="success">Add
                                            Facility</Button>
                                    </GridItem>
                                    <GridItem xs={2}>
                                        <Button
                                            onClick={() => addFacility(selectedItem.value, true)} color="info">Add
                                            Highlight</Button>
                                    </GridItem>
                                    <GridItem xs={1}>
                                        {
                                            load ? <Button
                                                    color="warning">Loading...</Button>
                                                :
                                                <Button
                                                    onClick={() => submit()} color="primary">Update</Button>
                                        }
                                    </GridItem>

                                    <GridItem xs={2}>
                                        <a href={"/admin/hotel/packages/" + data.id}>

                                            <Button type={"button"} color="info">Next Step</Button>

                                        </a>
                                    </GridItem>

                                </GridContainer>


                                {/*<ReactTable*/}
                                {/*data={facilities}*/}
                                {/*filterable*/}
                                {/*pageSize={facilities.length}*/}
                                {/*columns={[*/}
                                {/*{*/}
                                {/*Header: "Photo",*/}
                                {/*sortable: false,*/}
                                {/*filterable: false,*/}
                                {/*accessor: "photo",*/}
                                {/*Cell: row => (*/}
                                {/*<div className=" ">*/}
                                {/*<img width="50" src={apiUrl + row.value}/>*/}

                                {/*</div>*/}
                                {/*)*/}
                                {/*},*/}
                                {/*{*/}
                                {/*Header: "Name",*/}
                                {/*sortable: false,*/}
                                {/*accessor: "name"*/}
                                {/*},*/}

                                {/*{*/}
                                {/*Header: "Actions",*/}
                                {/*accessor: "id",*/}
                                {/*sortable: false,*/}
                                {/*filterable: false,*/}
                                {/*Cell: row => (*/}
                                {/*<div className="actions-right">*/}
                                {/*{renderChecks(row.value)}*/}
                                {/*</div>*/}
                                {/*)*/}
                                {/*},*/}
                                {/*]}*/}

                                {/*loading={props.loading}*/}
                                {/*showPaginationBottom={false}*/}
                                {/*className="-striped -highlight"*/}
                                {/*/>*/}
                            </CardBody>
                        </Fragment> : <div style={{padding: 50}}>
                            Loading...
                        </div>
                    }

                </Card>
            </GridItem>

            {
                !props.loading && renderItems()
            }

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    facilities: state.hotel.facilities,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)