import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Group";
import Dvr from "@material-ui/icons/Dvr";
import Pages from "@material-ui/icons/Pages";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import PromoCodeAction from "actions/PromoCode/PromoCodeAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
// import List from "@material-ui/icons/Payment";
import promoCodes from "../../reducers/PromoCode";
import helper from 'Utils/helper.js';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function PromoCodes(props) {

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        dispatch(PromoCodeAction.GetPromoCodes(client));
    }
    const classes = useStyles();
    const {pagination, data, loading, user_details} = props;

    console.log(data)
    return (

        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Person/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Promo Codes</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={2}>
                                {
                                    (helper.isAdmin(user_details.type) ||
                                        helper.hasPermission("update_promo_code", user_details.roles)) &&
                                    <a target={"_blank"}
                                       href={"/admin/add-promo-code"}><Button
                                        color="info">Add New promo Code</Button></a>
                                }
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTableCustom
                            columns={[
                                {
                                    Header: "Code",
                                    sortable: false,
                                    accessor: "code"
                                },
                                {
                                    Header: "Commission Percent",
                                    sortable: false,
                                    accessor: "commission"
                                },
                                {
                                    Header: "Discount Price",
                                    sortable: false,
                                    accessor: "price"
                                },
                                {
                                    Header: "Max Discount Price",
                                    sortable: false,
                                    accessor: "max_price"
                                },
                                {
                                    Header: "Discount Percent",
                                    sortable: false,
                                    accessor: "percent"
                                },
                                {
                                    Header: "Promo Type",
                                    sortable: false,
                                    accessor: "type"
                                },
                                {
                                    Header: "Start",
                                    sortable: false,
                                    accessor: "from"
                                },
                                {
                                    Header: "End",
                                    sortable: false,
                                    accessor: "to"
                                },
                                // {
                                //     Header: "Is Infinite",
                                //     accessor: "infinite",
                                //     sortable: false,
                                //     Cell: row => (
                                //         <div>
                                //             {row.value == 1 ? "yes" : "no"}
                                //
                                //         </div>
                                //     )
                                // },
                                //
                                // {
                                //     Header: "Is Used",
                                //     accessor: "used",
                                //     sortable: false,
                                //     Cell: row => (
                                //         <div>
                                //             {row.value == 1 ? "yes" : "no"}
                                //
                                //         </div>
                                //     )
                                // },
                                {
                                    Header: "Is Active",
                                    accessor: "active",
                                    sortable: false,
                                    Cell: row => (
                                        <div>
                                            {row.value == 1 ? "yes" : "no"}

                                        </div>
                                    )
                                },
                                {
                                    Header: "Actions",
                                    accessor: "id",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">

                                            {console.log(row)}

                                            {


                                                (helper.isAdmin(user_details.type) ||
                                                    helper.hasPermission("edit_promo_code_commissions", user_details.roles)) &&
                                                <NavLink
                                                    to={"/admin/edit-promo-code/" + row.value}
                                                    target={"_blank"}

                                                >
                                                    <Tooltip
                                                        id="tooltip-top"
                                                        title="Edit Commission"
                                                        placement="bottom"
                                                        classes={{tooltip: classes.tooltip}}
                                                    >

                                                        <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            onClick={() => {

                                                            }}
                                                            color="warning"
                                                        >

                                                            <Edit/>
                                                        </Button>
                                                    </Tooltip>
                                                </NavLink>

                                            }

                                            {" "}
                                            <NavLink
                                                to={"/admin/promo-code-transactions/" + row.row.code}
                                                target={"_blank"}

                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Transactions"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        onClick={() => {

                                                        }}
                                                        color="info"
                                                    >

                                                        <List/>
                                                    </Button>
                                                </Tooltip>
                                            </NavLink>

                                        </div>
                                    )
                                },
                            ]}
                            pagination={pagination}
                            loading={loading}
                            data={data}
                        />

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.promoCodes.data,
    pagination: state.promoCodes.pagination,
    loading: state.promoCodes.loading,
    error: state.promoCodes.error,
})

const mapDispatchToProps = dispatch => ({
    PromoCodeAction: bindActionCreators({PromoCodeAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCodes)