const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,

});
const rialFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'IRR',
    minimumFractionDigits: 0,

});


function dateDiffInDays(first_date, second_date) {
    const a = new Date(first_date), b = new Date(second_date);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const hasPermission = (permission, roles) => {
    console.log(permission)

    let has = roles.filter(e => {
        if (e.role == permission) {
            return e
        }
    });
    return has.length > 0

};
const isAdmin = (type) => {
    console.log(type)

    return type === "admin"

};
export default {
    formatter,
    isAdmin,
    hasPermission,
    dateDiffInDays,
    rialFormatter
}