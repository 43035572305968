import gql from 'graphql-tag';
import {LOGIN_FAILED, LOGIN_SUCCESS, LOGIN_REQUEST} from "../../types/login";

const Login = (client, user, pass) => {

    return dispatch => {
        dispatch(request())
        return client.mutate({
            variables: {auth: true}
            ,
            mutation: gql`
mutation{
  PanelUserLogin(input:{username:"${user}",password:"${pass}"},client:{id:"4",secret:"Wio3Nr2XFBWPVEIAOiV2E5U2FoWdE8oNvz7EGloC"}){
    access_token
    refresh_token
    expires_in
  }
}
                 `
        }).then(res => {
            dispatch(success(res.data))
        }).catch(error => {
            dispatch(failure(error))
        })

        function request() {
            return {type: LOGIN_REQUEST}
        }

        function success(result) {
            return {type: LOGIN_SUCCESS, payload: result.PanelUserLogin}
        }

        function failure(error) {
            return {type: LOGIN_FAILED, error: {error}}
        }
    }
};

export default {
    Login,
}