import {
    GET_HOTELS_REQUESTS_REQUEST,
    GET_HOTELS_REQUESTS_SUCCESS,
    GET_HOTELS_REQUESTS_FAILED,
    GET_HOTEL_REQUEST_REQUEST,
    GET_HOTEL_REQUEST_SUCCESS,
    GET_HOTEL_REQUEST_FAILED,
    GET_HOTEL_RESPONE_REQUEST,
    GET_HOTEL_RESPONE_SUCCESS,
    GET_HOTEL_RESPONE_FAILED

} from "../types/requests";


const initialState = {
    hotel_data: [],
    pagination: {
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 15,
        from: 0,
        to: 0
    },
    loading: true,
    error: {},
    hotel_request: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_HOTEL_RESPONE_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_HOTEL_RESPONE_SUCCESS: {
            return {
                ...state,
                loading: false,
                hotel_request: action.payload,
            }
        }
        case GET_HOTEL_RESPONE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_HOTEL_REQUEST_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_HOTEL_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                hotel_request: action.payload,
            }
        }
        case GET_HOTEL_REQUEST_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_HOTELS_REQUESTS_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_HOTELS_REQUESTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                hotel_data: action.payload,
            }
        }
        case GET_HOTELS_REQUESTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }

        default:
            return state

    }
}