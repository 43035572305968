import React, {Fragment, useEffect} from "react";
// react component for creating dynamic tables
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Home from "@material-ui/icons/Home";
import Cancel from "@material-ui/icons/Cancel";
import Pages from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import OthersAction from "actions/Others";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CardText from "components/Card/CardText.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Check from "@material-ui/icons/Check";

// import Select from 'react-select';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import helper from 'Utils/helper.js';
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import UserAction from "actions/auth/UserAction";
import SelectSearch from "react-select";

const useStyles = makeStyles({
    ...stylesAlert,
    ...styles
});
const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
}));

function Index(props) {

    const {dispatch, client} = props;
    const classes = useStyles();
    const classe = useStyle();
    const {data, cities, user_details, users} = props;
    const [loading, setLoading] = React.useState(false);
    const handleChanges = selectedOption => {
        setSlectedCity(selectedOption.target.value)

    };
    const [checked, setChecked] = React.useState("0");
    const [hotelId, setHotelId] = React.useState(0);
    const [changed, setChanged] = React.useState(false);
    const [traditional, setTraditional] = React.useState(false);
    const [slectedCity, setSlectedCity] = React.useState({});
    const [selectedUser, setSelecteduser] = React.useState(null);
    const [overview, setOverview] = React.useState();
    const [metaKey, setMetaKey] = React.useState();
    const [metaDescription, setMetaDescription] = React.useState();
    const [hotelClass, setHotelClass] = React.useState();
    const [hotelType, setHotelType] = React.useState();
    const [pets, setPets] = React.useState(false);
    const [smoking, setSmoking] = React.useState(false);

    const [populated, setPopulated] = React.useState(false);
    const [recommended, setRecommended] = React.useState(false);
    const [instant, setInstant] = React.useState(false);
    const [best_seller, setBest_seller] = React.useState(false);
    const [alerts, setAlert] = React.useState(null);

    const successUpload = (desc, title) => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {desc}
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };
    const handleChange = event => {
        setChecked(event.target.value)
    };
    const renderCities = (key) => {
        console.log(slectedCity)
        let component =
            key.map(item => {
                return {value: item.id, label: item.name}
            })

        return component;
    }
    const handleChangeSelect = selectedOption => {
        setSlectedCity(selectedOption)
    };
    const renderUsers = (key) => {
        let component = (
            key.map(item => {
                return <MenuItem value={item.id}>{item.first_name + " " + item.last_name}</MenuItem>
            })
        );
        return component;
    };

    useEffect(() => {
        let id = props.match.params.hotel_id;
        setHotelId(id);
        dispatch(HotelAction.getHotel(client, id));
        dispatch(OthersAction.getCities(client));
        if (helper.isAdmin(user_details.type) ||
            helper.hasPermission("hotel_special_items", user_details.roles))
            dispatch(UserAction.GetUserList(client, {per_page: 1000, page: 1}));


    }, props.loading);

    useEffect(() => {
        if (data.place && !changed) {
            setMetaDescription(data.place && data.place.meta_description);
            setMetaKey(data.place && data.place.meta_key);
            setSlectedCity(data.place && {value: data.place.city.id, label: data.place.city.name});
            setChecked(`${data.stars}`);
            setHotelType(`${data.type}`);
            setHotelClass(`${data.class}`);
            setSelecteduser(data.panel_user_id);
            setTraditional(data.traditional);
            setRecommended(data.recommended);
            setBest_seller(data.best_seller);
            setOverview(data.place && data.place.overview);
            setPopulated(data.populated);
            setInstant(data.instant);
            setPets(data.terms_data && data.terms_data.pets);
            setSmoking(data.terms_data && data.terms_data.smoking);
            setChanged(true);
        }

    });


    const submit = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target);
        fd.append("overview", overview);
        fd.append("traditional", traditional ? "1" : "0");
        fd.append("populated", populated ? "1" : "0");
        fd.append("recommended", recommended ? "1" : "0");
        fd.append("instant", instant ? "1" : "0");
        fd.append("best_seller", best_seller ? "1" : "0");
        setLoading(true)
        dispatch(HotelAction.UpdateHotel(client, fd, hotelId, data.place.id)).then(res => {

            setLoading(false)
            successUpload("Hotel basic Data Has Been Updated.", "Updated")

        })
    }

    const updateHotelAddress = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target);
        setLoading(true)
        dispatch(HotelAction.UpdateHotelAddress(client, fd, hotelId)).then(res => {
            setLoading(false)
            successUpload("Hotel Address Data Has Been Updated.", "Updated")

        })
    }
    const updateHotelPolicies = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target);
        setLoading(true)

        dispatch(HotelAction.UpdateHotelPolicies(client, fd, hotelId)).then(res => {
            setLoading(false)
            successUpload("Hotel Policies Data Has Been Updated.", "Updated")

        })
    }
    const updateTerms = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target);
        fd.append("pets", pets ? "1" : "0");
        fd.append("smoking", smoking ? "1" : "0");

        setLoading(true)
        dispatch(HotelAction.updateTerms(client, fd, hotelId)).then(res => {
            setLoading(false)
            successUpload("Hotel Terms Data Has Been Updated.", "Updated")

        })
    }

    if (props.loading)
        return "Loading ...";

    return (

        <GridContainer>
            {
                alerts
            }
            <GridItem xs={12}>
                <Button href="#" className={classes.title} style={{fontSize: "1.2rem"}} color="transparent">
                    Edit {data.name}
                </Button>
            </GridItem>
            <GridItem md={6}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Edit/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Edit Hotel</h4>
                    </CardHeader>
                    <form onSubmit={e => submit(e)}>
                        <CardBody>
                            <GridContainer xs={12}>
                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Hotel Name
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="name"

                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                name: "name",
                                                defaultValue: data.name
                                            }}
                                        />

                                    </GridItem>

                                </GridContainer>
                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Hotel Code
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="code"

                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "number",
                                                name: "code",
                                                defaultValue: data.code
                                            }}
                                        />

                                    </GridItem>

                                </GridContainer>
                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>City</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <div style={{paddingTop: 29}}>
                                            <FormControl className={classe.formControl}>
                                                <SelectSearch
                                                    value={slectedCity}
                                                    name={"city"}
                                                    onChange={handleChangeSelect}
                                                    options={renderCities(cities)}
                                                />

                                            </FormControl>
                                        </div>

                                    </GridItem>

                                </GridContainer>

                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Hotel Class</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <div style={{paddingTop: 29}}>
                                            <FormControl className={classe.formControl}>
                                                <Select
                                                    value={hotelClass}
                                                    onChange={e => setHotelClass(e.target.value)}
                                                    inputProps={{
                                                        name: 'class',
                                                    }}
                                                >
                                                    <MenuItem value="luxury">Luxury</MenuItem>
                                                    <MenuItem value="business">Business</MenuItem>
                                                    <MenuItem value="economy">Economy</MenuItem>
                                                    <MenuItem value="luxury_business">Luxury & Business</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                    </GridItem>

                                </GridContainer>



                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Hotel Type</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <div style={{paddingTop: 29}}>
                                            <FormControl className={classe.formControl}>
                                                <Select
                                                    value={hotelType}
                                                    onChange={e => setHotelType(e.target.value)}
                                                    inputProps={{
                                                        name: 'type',
                                                    }}
                                                >
                                                    <MenuItem value="hotel">Hotel</MenuItem>
                                                    <MenuItem value="hostel">Hostel</MenuItem>
                                                    <MenuItem value="boutique_hotel">Boutique Hotel</MenuItem>
                                                    <MenuItem value="guesthouse_ecolodge">Guesthouse &
                                                        Ecolodge</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                    </GridItem>

                                </GridContainer>

                                {
                                    hotelType == "hotel" && <GridContainer md={12}>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>Stars</FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <div
                                                style={{paddingTop: 25}}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === '0'}
                                                            onChange={handleChange}
                                                            value="0"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="No Star"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === "1"}
                                                            onChange={handleChange}
                                                            value="1"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="1 Star"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === "2"}
                                                            onChange={handleChange}
                                                            value="2"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="2 Stars"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === "3"}
                                                            onChange={handleChange}
                                                            value="3"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="3 Stars"
                                                />

                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === "4"}
                                                            onChange={handleChange}
                                                            value="4"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="4 Stars"
                                                />

                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={checked === "5"}
                                                            onChange={handleChange}
                                                            value="5"
                                                            name="stars"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="5 Stars"
                                                />


                                            </div>
                                        </GridItem>

                                    </GridContainer>

                                }
                                <GridContainer md={9} justify="flex-end">
                                    <GridItem xs={12} sm={12} md={8}>
                                        <div className={classes.checkboxAndRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked={data.traditional}
                                                        onClick={() => setTraditional(!traditional)}
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon}/>
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon}/>}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}

                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Traditional"
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                {
                                    (helper.isAdmin(user_details.type) ||
                                        helper.hasPermission("hotel_special_items", user_details.roles)) &&
                                    <Fragment>
                                        <GridContainer md={9} justify="flex-end">
                                            <GridItem xs={12} sm={12} md={8}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={data.best_seller}
                                                                onClick={() => setBest_seller(!best_seller)}

                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon}/>
                                                                }
                                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}

                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label="Best Seller"
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer md={9} justify="flex-end">
                                            <GridItem xs={12} sm={12} md={8}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={() => setRecommended(!recommended)}
                                                                defaultChecked={data.recommended}

                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon}/>
                                                                }
                                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}

                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label="Recommended"
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer md={9} justify="flex-end">
                                            <GridItem xs={12} sm={12} md={8}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={() => setPopulated(!populated)}
                                                                defaultChecked={data.populated}

                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon}/>
                                                                }
                                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}

                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label="Popular"
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer md={9} justify="flex-end">
                                            <GridItem xs={12} sm={12} md={8}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={() => setInstant(!instant)}
                                                                defaultChecked={data.instant}

                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon}/>
                                                                }
                                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}

                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label="Instant Booking"
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </Fragment>
                                }


                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Latitude</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="latitude"

                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                name: "lat",
                                                defaultValue: data.place && data.place.lat,
                                                required: "required"
                                            }}
                                        />
                                    </GridItem>

                                </GridContainer>

                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Longitude</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CustomInput
                                            id="longitude"

                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "text",
                                                name: "lng",
                                                defaultValue: data.place && data.place.lng,
                                                required: "required"
                                            }}
                                        />
                                    </GridItem>

                                </GridContainer>

                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Meta Key</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <TextareaAutosize
                                            className={classes.textArea}
                                            rows={5}
                                            name={"meta_key"}
                                            onChange={event => setMetaKey(event.target.value)}
                                            value={metaKey}
                                        />
                                    </GridItem>

                                </GridContainer>
                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Meta Description</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <TextareaAutosize
                                            className={classes.textArea}
                                            rows={5}
                                            name={"meta_description"}
                                            onChange={event => setMetaDescription(event.target.value)}
                                            value={metaDescription}

                                        />
                                    </GridItem>

                                </GridContainer>

                                <GridContainer md={12}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <FormLabel className={classes.labelHorizontal}>Overview</FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={data.place && data.place.overview}
                                            onInit={editor => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setOverview(data);
                                            }}
                                            onBlur={(event, editor) => {
                                            }}
                                            onFocus={(event, editor) => {
                                            }}
                                        />
                                    </GridItem>

                                </GridContainer>
                                {
                                    (helper.isAdmin(user_details.type) ||
                                        helper.hasPermission("add_user_to_hotel_permission", user_details.roles)) &&
                                    <GridContainer md={12}>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormLabel className={classes.labelHorizontal}>User</FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <div style={{paddingTop: 29}}>
                                                <FormControl className={classe.formControl}>
                                                    <Select
                                                        value={selectedUser}
                                                        onChange={e => setSelecteduser(e.target.value)}
                                                        inputProps={{
                                                            name: 'panel_user_id',
                                                        }}
                                                    >
                                                        <MenuItem value={null}>No User</MenuItem>
                                                        {renderUsers(users)}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                        </GridItem>

                                    </GridContainer>
                                }

                            </GridContainer>

                        </CardBody>
                        <CardFooter>
                            <GridContainer justify="flex-end">
                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        loading ?
                                            <Button color="warning">Please Wait...</Button> :
                                            <Button type={"submit"} color="primary">Save</Button>

                                    }
                                </GridItem>
                            </GridContainer>
                        </CardFooter>
                    </form>

                </Card>
            </GridItem>

            <GridItem md={6}>
                <GridContainer>

                    <GridItem md={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="info">
                                    <Home/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Hotel Address</h4>
                            </CardHeader>
                            <form onSubmit={e => updateHotelAddress(e)}>
                                <CardBody>
                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Hotel Address</FormLabel>

                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={9}>
                                                <TextField
                                                    id="standard-multiline-flexible"
                                                    label=""
                                                    multiline
                                                    rowsMax="5"
                                                    name="address"
                                                    defaultValue={data.address_data && data.address_data.address}
                                                    className={classe.textField}
                                                    margin="normal"
                                                />
                                            </GridItem>
                                        </GridContainer>

                                    </GridContainer>

                                </CardBody>
                                <CardFooter>
                                    <GridContainer justify="flex-end">
                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                loading ?
                                                    <Button color="warning">Please Wait...</Button> :
                                                    <Button type={"submit"} color="info">Save</Button>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </form>

                        </Card>
                    </GridItem>
                    <GridItem md={12}>
                        <Card>
                            <CardHeader color="danger" icon>
                                <CardIcon color="danger">
                                    <Cancel/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Cancellation/Prepayment</h4>
                            </CardHeader>
                            <form onSubmit={e => updateHotelPolicies(e)}>
                                <CardBody>
                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Details</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <TextField
                                                    id="standard-multiline-flexible"
                                                    label=""
                                                    name={"policies"}
                                                    multiline
                                                    rowsMax="5"
                                                    defaultValue={data.cancellation_conditions && data.cancellation_conditions.policies}
                                                    className={classe.textField}
                                                    margin="normal"
                                                />
                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>

                                </CardBody>
                                <CardFooter>
                                    <GridContainer justify="flex-end">
                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                loading ?
                                                    <Button color="warning">Please Wait...</Button> :
                                                    <Button type={"submit"} color="danger">Save</Button>
                                            }

                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </form>

                        </Card>
                    </GridItem>

                    <GridItem md={12}>
                        <Card>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <Pages/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Policies</h4>
                            </CardHeader>
                            <form onSubmit={e => updateTerms(e)}>
                                <CardBody>
                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Check-in</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <CustomInput

                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "checkin",
                                                        defaultValue: data.terms_data && data.terms_data.check_in
                                                    }}
                                                />

                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>
                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Check-out</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <CustomInput
                                                    id="name"

                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "text",
                                                        name: "checkout",
                                                        defaultValue: data.terms_data && data.terms_data.checkout
                                                    }}
                                                />

                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>
                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Good to Know</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <TextField
                                                    id="standard-multiline-flexible"
                                                    label=""
                                                    multiline
                                                    rowsMax="5"
                                                    name="good_to_know"
                                                    defaultValue={data.terms_data && data.terms_data.good_to_know}
                                                    className={classe.textField}
                                                    margin="normal"
                                                />
                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>

                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Payment
                                                    Method</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <TextField
                                                    id="standard-multiline-flexible"
                                                    label=""
                                                    multiline
                                                    rowsMax="5"
                                                    name="payment"
                                                    defaultValue={data.terms_data && data.terms_data.payment}
                                                    className={classe.textField}
                                                    margin="normal"
                                                />
                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>

                                    <GridContainer xs={12}>
                                        <GridContainer md={12}>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormLabel className={classes.labelHorizontal}>Children & Extra
                                                    Beds</FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <TextField
                                                    id="standard-multiline-flexible"
                                                    label=""
                                                    multiline
                                                    rowsMax="5"
                                                    name="children"
                                                    defaultValue={data.terms_data && data.terms_data.children}
                                                    className={classe.textField}
                                                    margin="normal"
                                                />
                                            </GridItem>

                                        </GridContainer>

                                    </GridContainer>
                                    <GridContainer md={9} justify="flex-end">
                                        <GridItem xs={12} sm={12} md={8}>
                                            <div className={classes.checkboxAndRadio}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            defaultChecked={data.terms_data && data.terms_data.pets}
                                                            onClick={() => setPets(!pets)}

                                                            checkedIcon={
                                                                <Check className={classes.checkedIcon}/>
                                                            }
                                                            icon={<Check className={classes.uncheckedIcon}/>}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}

                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="Allow Pets"
                                                />
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer md={9} justify="flex-end">
                                        <GridItem xs={12} sm={12} md={8}>
                                            <div className={classes.checkboxAndRadio}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            defaultChecked={data.terms_data && data.terms_data.smoking}
                                                            onClick={() => setSmoking(!smoking)}

                                                            checkedIcon={
                                                                <Check className={classes.checkedIcon}/>
                                                            }
                                                            icon={<Check className={classes.uncheckedIcon}/>}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}

                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="Smoking"
                                                />
                                            </div>
                                        </GridItem>
                                    </GridContainer>


                                </CardBody>
                                <CardFooter>
                                    <GridContainer justify="flex-end">
                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                loading ?
                                                    <Button color="warning">Please Wait...</Button> :
                                                    <Button type={"submit"} color="warning">Save</Button>

                                            }
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </form>
                        </Card>

                    </GridItem>
                    <GridItem md={12}>
                        <a href={"/admin/hotel/photos/" + hotelId}>

                            <Button type={"submit"} color="info">Next Step</Button>

                        </a>
                    </GridItem>
                </GridContainer>

            </GridItem>

        </GridContainer>
    );
}


const mapStateToProps = state => ({
    cities: state.hotel.cities,
    users: state.user.data,
    data: state.hotel.hotel,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)