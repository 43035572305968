import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import LoginAction from "actions/auth/LoginAction";
import connect from "react-redux/es/connect/connect";

import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);


function LoginPage(props) {
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 700);


    const classes = useStyles();
    const loginOrRegister = (e) => {

        e.preventDefault();
        const {dispatch, client} = props;

        const fd = new FormData(e.target);


        dispatch(LoginAction.Login(client, fd.get("email"), fd.get("password")));

        return true;
    }

    useEffect(() => {


        if (props.data.access_token) {

            localStorage.setItem("access_token", props.data.access_token)

            window.location.href = "/"
        }
    });
    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form onSubmit={e => loginOrRegister(e)}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Log in</h4>
                                {/*<div className={classes.socialLine}>*/}
                                    {/*{[*/}
                                        {/*"fab fa-facebook-square",*/}
                                        {/*"fab fa-twitter",*/}
                                        {/*"fab fa-google-plus"*/}
                                    {/*].map((prop, key) => {*/}
                                        {/*return (*/}
                                            {/*<Button*/}
                                                {/*color="transparent"*/}
                                                {/*justIcon*/}
                                                {/*key={key}*/}
                                                {/*className={classes.customButtonClass}*/}
                                            {/*>*/}
                                                {/*<i className={prop}/>*/}
                                            {/*</Button>*/}
                                        {/*);*/}
                                    {/*})}*/}
                                {/*</div>*/}
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Email..."
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: "email",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon}/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="rose" simple type="submit" size="lg" block>
                                    Let's Go
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const mapStateToProps = state => ({
    data: state.login.data,
    loading: state.login.loading,
    error: state.login.error,
})

const mapDispatchToProps = dispatch => ({
    LoginAction: bindActionCreators({LoginAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)