import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED
} from '../types/login'

const initialState = {
    data: {},
    loading: true,
    error: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return {
                loading: true,
                data: {}
            }
        }
        case LOGIN_SUCCESS: {
            return {
                loading: false,
                data: action.payload
            }
        }
        case LOGIN_FAILED: {
            return {
                loading: false,
                error: action.error,
                data: {}
            }
        }
        default:
            return state
    }
}