import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Assignment from "@material-ui/icons/Assignment";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Check from "@material-ui/core/SvgIcon/SvgIcon";

const useStyles = makeStyles(styles);

function Index(props) {

    const {dispatch, client} = props;
    const classes = useStyles();
    const {data} = props;
    const [overview, setOverview] = React.useState("");
    const [checked, setChecked] = React.useState(false);
    const [roomData, setRoomData] = React.useState({});
    const [isPricePerPerson, setIsPricePerPerson] = React.useState(false);
    const [isSuggested, setIsSuggested] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleToggle = value => {
        setChecked(!checked);

    };
    const submit = (e) => {
        e.preventDefault();
        let id = props.match.params.hotel_id;
        let package_id = props.match.params.package_id;
        const {client} = props;
        let fd = new FormData(e.target)
        setLoading(true)
        fd.append("description", overview)
        fd.append("is_price_per_person", isPricePerPerson ? "1" : "0");
        fd.append("is_suggested", isSuggested ? "1" : "0");
        dispatch(HotelAction.updateHotelRoom(client, 0, package_id, id, fd)).then(res => {
            setLoading(false)

            window.location.href = `/admin/hotel/room-edit/${package_id}/${id}/${res.id}`


        })

    }


    return (


        <GridContainer>
            <GridItem xs={6}>
                <Card>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <Edit/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Add Room </h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={(e) => submit(e)}>

                            <GridContainer xs={12}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room Name"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "name",
                                            required: "required",
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room Capacity"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "capacity",
                                            required: "required",

                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room/Person Count"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "room_count",
                                            required: "required",

                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Price"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "price",
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox

                                                onClick={() => setIsPricePerPerson(!isPricePerPerson)}


                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label="is price per person"
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Discount"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "price_discount",
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Title"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "title",
                                        }}
                                    />

                                </GridItem>

                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Subtitle"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "sub_title",
                                        }}
                                    />

                                </GridItem>

                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Description"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "term_description",
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={() => setIsSuggested(!isSuggested)}

                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label="Is Suggested by IranRoute"
                                    />

                                </GridItem>
                                <GridContainer md={1}>


                                </GridContainer>


                                <GridItem xs={12} sm={12} md={12}>
                                    {
                                        loading ? <Button type={"button"} color="warning">Loading...</Button>

                                            : <Button type={"submit"} color="warning">Save</Button>


                                    }

                                </GridItem>

                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    pagination: state.hotel.pagination,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)