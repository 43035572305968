import {
    GET_HOTELS_REQUEST,
    GET_HOTELS_SUCCESS,
    GET_HOTELS_FAILED,
    GET_HOTEL_BASIC_DATA_REQUEST,
    GET_HOTEL_BASIC_DATA_SUCCESS,
    GET_HOTEL_BASIC_DATA_FAILED,
    GET_FACILITIES_REQUEST,
    GET_FACILITIES_SUCCESS,
    GET_FACILITIES_FAILED,

} from "../types/hotel";
import {
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAILED,
} from "../types/other";

const initialState = {
    data: [],
    cities: [],
    facilities: [],
    pagination: {
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 15,
        from: 0,
        to: 0
    },
    loading: true,
    error: {},
    hotel: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_FACILITIES_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_FACILITIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                facilities: action.payload,
            }
        }
        case GET_FACILITIES_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_CITY_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_CITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                cities: action.payload,
            }
        }
        case GET_CITY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_HOTELS_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_HOTELS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                pagination: action.pagination
            }
        }
        case GET_HOTELS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }

        case GET_HOTEL_BASIC_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_HOTEL_BASIC_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                hotel: action.payload,
            }
        }
        case GET_HOTEL_BASIC_DATA_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }

        default:
            return state

    }
}