let apiUrl = 'https://api.iranroute.com/';
// let apiUrl = 'http://localhost:8000/';
let secretKey = 'Wio3Nr2XFBWPVEIAOiV2E5U2FoWdE8oNvz7EGloC';
let clientId = '4';
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.iranroute.com/';
    secretKey = 'Wio3Nr2XFBWPVEIAOiV2E5U2FoWdE8oNvz7EGloC'
}
export {
    apiUrl,
    secretKey,
    clientId,
}