import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import TransactionAction from "actions/TransactionAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import List from "@material-ui/icons/Payment";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Photo from "@material-ui/core/SvgIcon/SvgIcon";
import Utility from "Utils/helper.js";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = () => {
        client.cache.reset()
        dispatch(TransactionAction.GetPayoutRequests(client));
    }
    const classes = useStyles();
    const {data, loading} = props;
    return (

        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <List/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Payout Requests</h4>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={data}
                            pageSize={data.length}
                            showPaginationBottom={false}
                            columns={[


                                {
                                    Header: "First Name",
                                    filterable: true,
                                    sortable: false,
                                    accessor: "user.first_name",


                                },

                                {
                                    Header: "Last Name",
                                    filterable: true,
                                    sortable: false,
                                    accessor: "user.last_name"
                                },

                                {
                                    Header: "Email",
                                    filterable: true,
                                    sortable: false,
                                    accessor: "user.email"

                                },

                                {
                                    Header: "Euro",
                                    sortable: false,
                                    accessor: "euro",
                                    Cell: row => (
                                        <div>
                                            {
                                                Utility.formatter.format(row.value)
                                            }
                                        </div>
                                    )

                                },
                                {
                                    Header: "Rials",
                                    sortable: false,
                                    accessor: "rials",
                                    Cell: row => (
                                        <div>
                                            {

                                                Utility.rialFormatter.format(row.value)
                                            }
                                        </div>
                                    )

                                },
                                {
                                    Header: "Payout Amount",
                                    sortable: false,
                                    accessor: "payout",
                                    Cell: row => (
                                        <div>
                                            {

                                                Utility.rialFormatter.format(row.value)
                                            }
                                        </div>
                                    )

                                },
                                {
                                    Header: "Actions",
                                    accessor: "user.id",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">


                                            <NavLink
                                                to={"/admin/payout-request/" + row.value}
                                                target={"_blank"}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Payout"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="info"
                                                        className="edit"
                                                    >


                                                        <List/>
                                                    </Button>
                                                </Tooltip>

                                            </NavLink>
                                            {" "}

                                        </div>
                                    )
                                }

                            ]}
                            loading={loading}
                        />

                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.transaction.payout_requests,
    loading: state.transaction.loading,
    error: state.transaction.error,
})

const mapDispatchToProps = dispatch => ({
    TransactionAction: bindActionCreators({TransactionAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)