import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import createApolloClient from './core/createApolloClient.client';
import {ApolloProvider} from '@apollo/react-hooks';
import createFetch from './createFetch';
import history from './history';
import {apiUrl} from './config';
import configureStore from './store'
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import {Provider} from 'react-redux';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();
const initialState = {}
const apolloClient = createApolloClient();
const fetch = createFetch(fetch, {
    baseUrl: apiUrl,
});

const store = configureStore(initialState, {
    apolloClient,
    fetch,
    history,
})

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <BrowserRouter history={hist}>
                <Switch>
                    <Route path="/rtl" component={RtlLayout}/>
                    <Route path="/auth"
                           render={props => <AuthLayout {...props} client={apolloClient} dispatch={store.dispatch}/>}/>
                    <Route path="/admin"
                           render={props => <AdminLayout {...props} client={apolloClient} dispatch={store.dispatch}/>}/>
                    <Redirect from="/" to="/auth"/>
                </Switch>
            </BrowserRouter>
        </Provider>
    </ApolloProvider>,
    document.getElementById("root")
);
