import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import UserAction from "actions/User/UserAction";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import TransactionAction from "actions/TransactionAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import List from "@material-ui/icons/List";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Photo from "@material-ui/core/SvgIcon/SvgIcon";
import Utility from "Utils/helper.js";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Datetime from "react-datetime";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {

    const {dispatch, client} = props;
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const [filters, setFilters] = React.useState({
        status: "",
        email: "",
        type: "",
        range: {from: date.toISOString().split('T')[0], to: new Date().toISOString().split('T')[0]}
    });
    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        client.cache.reset();
        dispatch(UserAction.GetLogs(client, pagination));
    }
    const classes = useStyles();
    const {pagination, data, loading} = props;

    const onFilteredChange = (column, value) => {

        if (column.length === 0)
            setFilters({
                ...filters,
                status: "", email: "", type: ""
            })

        column.map(e => {
            setFilters({
                ...filters,
                [e.id]: e.value
            })
        })

    }
    const onChangeDate = (type, e) => {


        setFilters({
            ...filters,
            range: {...filters.range, [type]: e.format("YYYY-MM-DD")}
        })

    }
    return (

        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <List/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>User Logs</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl fullWidth>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={"YYYY-MM-DD"}
                                        onChange={e => onChangeDate("from", e)}
                                        inputProps={{
                                            placeholder: "From",
                                        }}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl fullWidth>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={e => onChangeDate("to", e)}
                                        dateFormat={"YYYY-MM-DD"}
                                        inputProps={{
                                            placeholder: "To",
                                        }}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <Button onClick={e => fetchMore()} color="primary">Apply Filters</Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTableCustom
                            columns={[
                                {
                                    Header: "First Name",
                                    sortable: false,
                                    accessor: "user.first_name"
                                },

                                {
                                    Header: "Last Name",
                                    sortable: false,
                                    accessor: "user.last_name"
                                },

                                {
                                    Header: "Email",
                                    sortable: false,
                                    accessor: "user.email"
                                },


                                {
                                    Header: "Activity Details",
                                    sortable: false,
                                    accessor: "activity"
                                },


                                {
                                    Header: "Date",
                                    sortable: false,
                                    filterable: false,
                                    accessor: "created_at"
                                },
                                {
                                    Header: "Jalali Date",
                                    filterable: false,
                                    sortable: false,
                                    accessor: "persian_created_at"
                                },


                            ]}
                            pagination={pagination}
                            fetchMore={fetchMore}
                            onFilteredChange={onFilteredChange}
                            loading={loading}
                            data={data}
                        />

                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.user.user_logs,
    pagination: state.user.pagination,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)