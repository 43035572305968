import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Assignment from "@material-ui/icons/Assignment";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Check from "@material-ui/core/SvgIcon/SvgIcon";

const useStyles = makeStyles(styles);

function Index(props) {

    const {dispatch, client, data} = props;
    const classes = useStyles();
    const [overview, setOverview] = React.useState();
    const [roomData, setRoomData] = React.useState({});
    const [pakckageName, setPakckageName] = React.useState("");
    const [isPricePerPerson, setIsPricePerPerson] = React.useState(false);
    const [isSuggested, setIsSuggested] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [loadSave, setLoadSave] = React.useState(false);
    const [hotelId, setHotelId] = React.useState(0);
    const [packageid, setPackageId] = React.useState(0);
    const [roomId, setRoomId] = React.useState(0);


    const submit = (e) => {
        e.preventDefault();
        let id = props.match.params.hotel_id;
        let package_id = props.match.params.package_id;
        let room_id = props.match.params.room_id;
        const {client} = props;
        let fd = new FormData(e.target);
        fd.append("description", overview);
        fd.append("is_price_per_person", isPricePerPerson ? "1" : "0");
        fd.append("is_suggested", isSuggested ? "1" : "0");
        setLoadSave(true)
        dispatch(HotelAction.updateHotelRoom(client, room_id, package_id, id, fd)).then(res => {
            setLoadSave(false)

        })

    }
    useEffect(() => {
        if (roomData.description && !changed) {
            setOverview(roomData.description);
            setIsSuggested(roomData.terms_data && roomData.terms_data.is_suggested);
            setIsPricePerPerson(roomData.is_price_per_person);
            setChanged(true);
        }

    });

    useEffect(() => {
        let id = props.match.params.hotel_id;
        let room_id = props.match.params.room_id;
        let package_id = props.match.params.package_id;
        setHotelId(id)
        setPackageId(package_id)
        setRoomId(room_id)
        dispatch(HotelAction.getHotel(client, id)).then(res => {

            (res.payload.rooms).map((e, i) => {

                if (e.id == room_id) {

                    setRoomData(e)

                }

            });


        });


    }, props.loading);


    if (props.loading && loadSave)
        return "Loading ...";

    if (roomData.id == undefined)
        return "Loading ...";

    return (


        <GridContainer>

            <GridItem xs={6}>
                <Card>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <Edit/>
                        </CardIcon>
                        <h3 className={classes.cardIconTitle}><a target={"_blank"}
                                                                 href={"/admin/hotel/edit/" + data.id}>{data.name}</a>
                        </h3>
                        <h4 className={classes.cardIconTitle}>Edit Room: {roomData.name}</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={(e) => submit(e)}>

                            <GridContainer xs={12}>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room Name"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "name",
                                            required: "required",
                                            defaultValue: roomData.name,
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room Capacity"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "capacity",
                                            required: "required",
                                            defaultValue: roomData.capacity
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Room/Person Count"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "room_count",
                                            required: "required",
                                            defaultValue: roomData.room_count
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Price"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "price",
                                            defaultValue: roomData.price_full_board
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox

                                                onClick={() => setIsPricePerPerson(!isPricePerPerson)}

                                                defaultChecked={roomData.is_price_per_person}

                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label="is price per person"
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Discount"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "price_discount",
                                            defaultValue: roomData.price_discount
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Title"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "title",
                                            defaultValue: roomData.terms_data.title
                                        }}
                                    />

                                </GridItem>

                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Subtitle"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "sub_title",
                                            defaultValue: roomData.terms_data.sub_title
                                        }}
                                    />

                                </GridItem>

                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id="name"
                                        labelText={"Package Description"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "term_description",
                                            defaultValue: roomData.terms_data.description
                                        }}
                                    />

                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>

                                    <FormControlLabel
                                        control={
                                            <Checkbox

                                                onClick={() => setIsSuggested(!isSuggested)}

                                                defaultChecked={roomData.terms_data.is_suggested}

                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label="Is Suggested by IranRoute"
                                    />

                                </GridItem>
                                <GridContainer md={1}>


                                </GridContainer>

                                <GridItem xs={12} sm={5} md={5}>
                                    <Button type={"submit"} color="warning">Save</Button>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <a href={"/admin/hotel/room-photos/" + packageid + "/" + hotelId + "/" + roomId}>
                                        <Button type={"Button"} color="info">Next Step</Button>
                                    </a>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    pagination: state.hotel.pagination,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)