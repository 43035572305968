import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Group";
import Dvr from "@material-ui/icons/Dvr";
import Pages from "@material-ui/icons/Pages";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/auth/UserAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import List from "@material-ui/icons/Payment";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function UserList(props) {

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        dispatch(UserAction.GetUserList(client, pagination));
    }
    const classes = useStyles();
    const {pagination, data, loading} = props;

    return (

        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Person/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>User List</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={2}>
                                <a target={"_blank"}
                                   href={"/admin/add-new-user"}><Button
                                    color="info">Add New User</Button></a>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTableCustom
                            columns={[
                                {
                                    Header: "First Name",
                                    sortable: false,
                                    accessor: "first_name"
                                },
                                {
                                    Header: "Last Name",
                                    sortable: false,
                                    accessor: "last_name"
                                },
                                {
                                    Header: "Type",
                                    sortable: false,
                                    accessor: "type"
                                },
                                {
                                    Header: "Email",
                                    sortable: false,
                                    accessor: "email"
                                },
                                {
                                    Header: "Actions",
                                    accessor: "id",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">
                                            <NavLink
                                                to={"user/" + row.value}
                                                target={"_blank"}

                                            >

                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    onClick={() => {

                                                    }}
                                                    color="info"
                                                >

                                                    <Pages/>
                                                </Button>
                                            </NavLink>
                                            {" "}
                                            <NavLink
                                                to={"/admin/payout-request/" + row.value}
                                                target={"_blank"}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Wallet"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="info"
                                                        className="edit"
                                                    >


                                                        <List/>
                                                    </Button>
                                                </Tooltip>

                                            </NavLink>
                                            {" "}

                                        </div>
                                    )
                                },
                            ]}
                            pagination={pagination}
                            loading={loading}
                            data={data}
                        />

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.user.data,
    pagination: state.user.pagination,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserList)