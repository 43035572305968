import React, {Fragment, useEffect, useState} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Link from "@material-ui/icons/Link";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Group";
import Dvr from "@material-ui/icons/Dvr";
import Pages from "@material-ui/icons/Pages";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import PromoCodeAction from "actions/PromoCode/PromoCodeAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import List from "@material-ui/icons/Payment";
import promoCodes from "../../reducers/PromoCode";
import helper from 'Utils/helper.js';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";
import QRCode from 'qrcode.react';
import $ from 'jquery';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function PromoCodes(props) {

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        dispatch(PromoCodeAction.GetPromoCodes(client));
    }
    const generateLink = () => {

        switch (type) {
            case "hotel":
            case "hostel":
            case "boutique_hotel":
            case "guesthouse_ecolodge":
                setLinkValue("https://www.iranroute.com/online-booking/iran-hotels")
                break;
            case "all":
                setLinkValue("https://www.iranroute.com")
                break;
            case "visa":
                setLinkValue("https://www.iranroute.com/iran-visa-application")
                break;
            case "tours":
                setLinkValue("https://www.iranroute.com/iran-tours")
                break;
            case "bus":
                setLinkValue("https://www.iranroute.com/online-booking/iran-bus-tickets")
                break;
            case "insurance":
                setLinkValue("https://www.iranroute.com/iran-travel-insurance")
                break;
            case "additional":
                setLinkValue("https://www.iranroute.com/online-travel-services")
                break;
        }


    }
    const classes = useStyles();
    const {pagination, data, loading, user_details} = props;


    const [linkValue, setLinkValue] = useState("https://www.iranroute.com/")
    const [type, setType] = useState("")
    const copyToClipboard = () => {
        var copyText = document.getElementById("link");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
    };
    return (

        <GridContainer>


            <GridItem xs={12}>
                <Button href="#" className={classes.title} style={{fontSize: "1.2rem"}} color="transparent">
                    Generate Affiliated Links
                </Button>
            </GridItem>
            <GridItem md={6} xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Link/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{
                            "Generate Available Links"
                        }</h4>

                    </CardHeader>
                    <CardBody>

                        <GridContainer>


                            <GridItem xs={12} sm={12} md={8}>
                                <Select
                                    value={type}
                                    onChange={e => setType(e.target.value)}
                                    inputProps={{
                                        name: 'type',
                                    }}
                                >
                                    {
                                        (data || []).map((e, i) => {
                                            if (e.type == "all") {

                                                return [
                                                    <MenuItem value={"all"}>All</MenuItem>,
                                                    <MenuItem value={"hotel"}>Hotels</MenuItem>,
                                                    <MenuItem value={"visa"}>Visa</MenuItem>,
                                                    <MenuItem value={"insurance"}>Insurance</MenuItem>,
                                                    <MenuItem value={"bus"}>Bus</MenuItem>,
                                                    <MenuItem value={"tours"}>Tours</MenuItem>,
                                                    <MenuItem value={"additional"}>Additional Services</MenuItem>
                                                ]
                                            } else
                                                return <MenuItem value={e.type}>{e.type}</MenuItem>

                                        })
                                    }

                                </Select>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={1}>
                                <Button onClick={generateLink} type="button" color="success">Generate</Button>

                            </GridItem>

                        </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={6} xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Link/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{
                            "Generate Your Custom Link"
                        }</h4>

                    </CardHeader>
                    <CardBody>

                        <GridContainer>


                            <GridItem xs={12} sm={12} md={8}>
                                <CustomInput
                                    labelText={"Paste Link Here"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={e => setLinkValue(e.target.value)}

                                    inputProps={{
                                        type: "text",
                                        value: linkValue,
                                        onChange: event => {
                                            setLinkValue(event.target.value)
                                        },
                                    }}
                                />
                            </GridItem>


                        </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>

            <GridItem md={6} xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Link/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Qr code</h4>

                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardIconTitle}>Use this QR Code in your website or print it.</h4>

                        <br/>
                        <br/>
                        <QRCode imageSettings={{
                            src: "/logo.png",
                            width: "75",
                            height: "75",
                        }} level={"H"} fgColor={"#33cccc"} value={linkValue + "?merchant=" + user_details.merchant_id}
                                size={250}/>


                    </CardBody>
                </Card>
            </GridItem>

            <GridItem md={6} xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Link/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Copy Link</h4>

                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText={"Copy thi link"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={e => setLinkValue(e.target.value)}

                                inputProps={{
                                    type: "text",
                                    value: linkValue + "?merchant=" + user_details.merchant_id,
                                    id:"link",
                                    onChange: event => {
                                        setLinkValue(event.target.value)
                                    },
                                }}
                            />
                        </GridItem>

                         <Button onClick={copyToClipboard} type="button" color="success">Copy</Button>


                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.promoCodes.data,
    pagination: state.promoCodes.pagination,
    loading: state.promoCodes.loading,
    error: state.promoCodes.error,
})

const mapDispatchToProps = dispatch => ({
    PromoCodeAction: bindActionCreators({PromoCodeAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCodes)