import {
    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILED,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    GET_USER_WALLET_FAILED,
    GET_USER_WALLET_SUCCESS,
    GET_USER_WALLET_REQUEST,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_USER_LOGS_REQUEST,
    GET_USER_LOGS_SUCCESS,
    GET_USER_LOGS_FAILED
} from "../types/User";


const initialState = {
    data: [],
    user_logs: [],
    pagination: {
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 15,
        from: 0,
        to: 0
    },
    loading: true,
    loading_main: true,
    error: null,
    user: {},
    user_details: {},
    wallet: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_LOGS_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_USER_LOGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                user_logs: action.payload,
                pagination: action.pagination
            }
        }
        case GET_USER_LOGS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_USER_DETAILS_REQUEST: {
            return {
                ...state,
                loading_main: true,
            }
        }
        case GET_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                loading_main: false,
                user_details: action.payload,
            }
        }
        case GET_USER_DETAILS_FAILED: {
            return {
                ...state,
                loading_main: false,
                error: action.error,
            }
        }
        case GET_USER_WALLET_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_USER_WALLET_SUCCESS: {
            return {
                ...state,
                loading: false,
                wallet: action.payload,
            }
        }
        case GET_USER_WALLET_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_USER_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                pagination: action.pagination
            }
        }
        case GET_USER_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_USER_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.payload,
                pagination: action.pagination
            }
        }
        case GET_USER_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        default:
            return state

    }
}