import ApolloClient from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory'
import {apiUrl} from '../config';
import {RetryLink} from 'apollo-link-retry';

const uri = apiUrl + 'graphql/panel';
const uriLogin = apiUrl + 'graphql/panel-auth';
const cache = new InMemoryCache({addTypename: false});
const link = new RetryLink().split(
    (operation) => operation.variables.auth === true,
    new HttpLink({uri: uriLogin}),
    new HttpLink({uri: uri, headers: {Authorization: (() => 'Bearer ' + localStorage.getItem('access_token'))()}})
);


const client = new ApolloClient({

    link,
    cache
});

export default function createApolloClient() {
    return client;
}