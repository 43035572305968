import gql from 'graphql-tag';

import {
    GET_HOTELS_REQUEST,
    GET_HOTELS_SUCCESS,
    GET_HOTELS_FAILED,
    GET_HOTEL_BASIC_DATA_REQUEST,
    GET_HOTEL_BASIC_DATA_SUCCESS,
    GET_HOTEL_BASIC_DATA_FAILED,
    GET_FACILITIES_REQUEST,
    GET_FACILITIES_SUCCESS,
    GET_FACILITIES_FAILED,
} from "../../types/hotel";

const getHotelList = (client, pagination = {per_page: 15, page: 1}, filters) => {
    console.log(filters)
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
      query{
        GetAccommodationsSearch( pagination:{perPage:${pagination.per_page},page:${pagination.page}},
        input:{name:"${filters.name}"  ,city:"${filters.city}"
        }
        )        {
          items {
            id
            best_seller
            place{
              city {
                name
                id
                slug
              }
              id
              name
              meta_key
              meta_description
              slug
              overview
              lat
              lng
            }
            name
            address_data{
              address
            }
            cover
            photos{
              id
              photo
              is_cover
              meta_data{
                caption
              }
            }
            stars
            from_price
            facilities{
              id
              is_highlight
              name
              photo
            }
            facility_highlights{
              id
   name
   photo
         }
            rooms {
              id
              accommodation_id
              name
              description
              type
              capacity
              price_full_board
              photos {
                id
                title
                description
                photo
              }
              facilities{
                id
                name
                photo
              }
            }
          }
          pagination {
            total
            current_page
            last_page
            per_page
            from
            to
          }
        }
       }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTELS_REQUEST}
        }

        function success(result) {
            return {
                type: GET_HOTELS_SUCCESS,
                payload: result.GetAccommodationsSearch.items,
                pagination: result.GetAccommodationsSearch.pagination
            }
        }

        function failure(error) {
            return {type: GET_HOTELS_FAILED, error: {error}}
        }
    }
}
const getHotelRooms = (client, id) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
             query   {
               GetHotelRooms(hotel_id: ${id}) {
                 id
                 name
                 requests{
                   id
                   token
                   check_in
                   check_out
                   first_name
                   last_name
                   booked_by_hotel
                 }
               }
             }
            `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTELS_REQUEST}
        }

        function success(result) {
            return {
                type: GET_HOTELS_SUCCESS,
                payload: result.GetHotelRooms,
            }
        }

        function failure(error) {
            return {type: GET_HOTELS_FAILED, error: {error}}
        }
    }
}


const getHotel = (client, id) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query{
            GetAccommodation(accommodation_id: "${id}") {
              id
              place_id
              stars
              traditional
              panel_user_id
              best_seller
              populated
              recommended
              instant
              type
              class
               photos{
      photo
      id
      meta_data{
        caption
      }
    }
              place {
                city {
                  name
                  id
                }       
                lat
                lng
                meta_key
                meta_description
                slug
                overview
              }
              address_data{
                 address
              }
             facilities{
              id
              is_highlight
              name
              photo
                 }
              name
              code
               terms_data{
                 check_in
                 checkout
                 pets
                 smoking
                 good_to_know
                 payment
                 children
               }
               cancellation_conditions{
                 policies
               }
               rooms{
               room_count
               accommodation_id
               prices{
                  id
                  priceable_id
                  price
                  discount
                  end_date
                  start_date
                }
               terms_data{
                  title
                  description
                  sub_title
                  is_suggested
                }
               
                  facilities{
                      id
                      name
                      photo
                    }
                    photos {
                      id
                      title
                      photo
                      description
                    }
                  name
                  price_discount
                  room_package_id
                  is_price_per_person
                  capacity
                  price_full_board
                  description
                  id
                }
                RoomPackage {
                  id
                  name
                  accommodation_id
                  sleeps
                  count
                  notes
                  more_info
                  rooms {
                  terms_data{
                  title
                  description
                  sub_title
                  is_suggested
                }
                    facilities {
                      id
                      name
                      photo
                      is_highlight
                    }
                    photos {
                      id
                      title
                      photo
                      description
                    }
                    name
                    capacity
                    price_full_board
                    description
                    id
                  }
                }
            }
          }
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {

            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTEL_BASIC_DATA_REQUEST}
        }

        function success(result) {
            return {
                type: GET_HOTEL_BASIC_DATA_SUCCESS,
                payload: result.GetAccommodation,
            }
        }

        function failure(error) {
            return {type: GET_HOTEL_BASIC_DATA_FAILED, error: {error}}
        }
    }
}
const getFacilities = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
              query{
              GetFacilities{
                id
                name
                photo
              }
            }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {

            dispatch(failure(error))
        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

        function success(result) {
            return {
                type: GET_FACILITIES_SUCCESS,
                payload: result.GetFacilities,
            }
        }

        function failure(error) {
            return {type: GET_FACILITIES_FAILED, error: {error}}
        }
    }
}
const updateFacility = (client, id, facilities) => {
    return dispatch => {

        dispatch(request())
        return client.mutate({
            variables: {
                accommodation_id: id,
                facilities: facilities,
            },
            mutation: gql`
              mutation ($accommodation_id: Int!, $facilities: [HotelFacilitiesInput]) {
                  UpdateAccommodationFacilities(accommodation_id: $accommodation_id, facilities: $facilities) {
                    updatedAccommodation {
                      id
                      facilities {
                        id
                        name
                        photo
                      }
                    }
                    message
                  }
                }
          `
        }).then(result => {
            return result
        }).catch(error => {

        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}
const updateRoomFacility = (client, id, facilities) => {
    return dispatch => {

        dispatch(request())
        return client.mutate({
            variables: {
                room_id: id,
                facilities: facilities,
            },
            mutation: gql`
               mutation ($room_id: Int!, $facilities: [Int]) {
  UpdateRoomFacilities(room_id: $room_id, facilities: $facilities) {
    updatedAccommodation {
      id
      facilities {
        id
        name
        photo
      }
    }
  }
}

          `
        }).then(result => {
            return result
        }).catch(error => {

        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}
const updateHotelRoom = (client, id, room_package_id, accommodation_id, data) => {

    let name = data.get("name");
    let description = data.get("description");
    let capacity = data.get("capacity");
    let room_count = data.get("room_count");
    let price = data.get("price");
    let price_discount = data.get("price_discount");
    let title = data.get("title");
    let sub_title = data.get("sub_title");
    let term_description = data.get("term_description");
    let is_price_per_person = data.get("is_price_per_person");
    let is_suggested = data.get("is_suggested");


    return dispatch => {


        dispatch(request())
        return client.mutate({
            variables: {
                accommodation_id: accommodation_id,
                id: id,
                name: name,
                price: price,
                description: description,
                capacity: capacity,
                is_price_per_person: is_price_per_person,
                room_count: room_count,
                price_discount: price_discount,
                room_package_id: room_package_id,
                terms_data: {
                    title: title,
                    sub_title: sub_title,
                    is_suggested: is_suggested,
                    description: term_description
                },
            },
            mutation: gql`
              mutation ($accommodation_id: String!, $terms_data: RoomTermsDataInput, 
                     $is_price_per_person: Boolean, $room_count: Int, $name: String, $id: String, $description: String, $room_package_id: Int, $capacity: Int, $price: Float, $price_discount: Float) {
                      saveAccommRoom(accommodation_id: $accommodation_id, room_input: {id: $id, terms_data: $terms_data, 
                      is_price_per_person: $is_price_per_person, room_count: $room_count, name: $name, capacity: $capacity, room_package_id: $room_package_id, description: $description, price_full_board: $price, price_discount: $price_discount}) {
                        updatedAccommRoom {
                          id
                          accommodation_id
                          name
                          capacity
                        }
                      }
                    }
          `
        }).then(result => {
            return result.data.saveAccommRoom.updatedAccommRoom
        }).catch(error => {

            console.log(error)
        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}
const updateHotelRoomRequest = (client, data) => {

    let room_id = data.get("room_id");
    let check_in = data.get("check_in");
    let check_out = data.get("check_out");
    let count = data.get("count");


    return dispatch => {


        dispatch(request())
        return client.mutate({
            mutation: gql`
               mutation {
                  UpdateHotelReservation(
                    room_id: ${room_id}
                    count: ${count}
                    check_in: "${check_in}"
                    check_out: "${check_out}"
                  ) {
                    id
                    accommodation_id
                    name
                    description
                    type
                    capacity
                  }
                }
          `
        }).then(result => {
            return result
        }).catch(error => {
            console.log(error)
        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}

const updateRoomPrice = (client, data) => {

    let start_date = data.get("start_date");
    let end_date = data.get("end_date");
    let room_id = data.get("room_id");
    let discount = data.get("discount");
    let price = data.get("price");


    return dispatch => {


        return client.mutate({
            mutation: gql`
               mutation  {
  UpdatePrice(start_date:"${start_date}",end_date:"${end_date}",
    priceable_id:${room_id},priceable_type:"room",price:${price},discount:${discount}){
    id
    start_date
    end_date
    priceable_type
    price
    discount
  }
}
          `
        }).then(result => {
            return result
        }).catch(error => {

        });


    }
}
const UpdateRoomPackages = (client, data, id = 0) => {

    let accommodation_id = data.get('accommodation_id');
    let sleeps = data.get('sleeps');
    let count = data.get('count');
    let notes = data.get('notes');
    let more_info = data.get('more_info');
    let name = data.get('name');

    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
              mutation {
                      UpdateRoomPackages(id:${id},accommodation_id: ${accommodation_id}, 
                      sleeps: ${sleeps}, count: ${count}, notes: "${notes}", more_info: "${more_info}", name: "${name}") {
                        response_status
                        response_message
                        room_package {
                          id
                          rooms {
                            id
                            accommodation {
                              id
                            }
                          }
                        }
                      }
                    }
          `
        }).then(result => {
            return result
        }).catch(error => {

        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}
const DeleteRoomPackage = (client, id = 0) => {


    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
               mutation{
              RemoveRoomPackage(id:${id})
            }
          `
        }).then(result => {
            return result
        }).catch(error => {

        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}
const DeleteRoom = (client, id = 0) => {


    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
               mutation{
              RemoveRoom(id:${id})
            }
          `
        }).then(result => {
            console.log(result);
            return result
        }).catch(error => {
            console.log(error);

        });

        function request() {
            return {type: GET_FACILITIES_REQUEST}
        }

    }
}

const DeleteHotel = (client, id = 0) => {


    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
               mutation{
              RemoveHotel(id:${id})
            }
          `
        }).then(result => {
            console.log(result);
            return result
        }).catch(error => {
            console.log(error);

        });

        function request() {
            return {type: GET_HOTELS_REQUEST}
        }

    }
}

const RemovePrice = (client, id = 0) => {


    return dispatch => {

        return client.mutate({
            mutation: gql`
               mutation{
              RemovePrice(id:${id})
            }
          `
        }).then(result => {
            return result
        }).catch(error => {

        });


    }
}


const UpdateHotel = (client, data, id = 0, place_id = 0) => {

    let meta_key = data.get('meta_key');
    let name = data.get('name');
    let stars = data.get('stars');
    let meta_description = data.get('meta_description');
    let overview = data.get('overview');
    let lat = data.get('lat');
    let lng = data.get('lng');
    let traditional = data.get('traditional');
    let _class = data.get('class');
    let type = data.get('type');
    let city = data.get('city');
    let recommended = data.get('recommended');
    let populated = data.get('populated');
    let instant = data.get('instant');
    let best_seller = data.get('best_seller');
    let code = data.get('code');
    let panel_user_id = data.get('panel_user_id');

    return dispatch => {

        return client.mutate({
            variables: {
                meta_key: meta_key,
                meta_description: meta_description,
                panel_user_id: panel_user_id,
                id: id,
                name: name,
                code: code,
                overview: overview,
                city_id: city,
                lat: lat,
                lng: lng,
                placeId: place_id,
                stars: stars,
                traditional: traditional,
                recommended: recommended,
                populated: populated,
                instant: instant,
                best_seller: best_seller,
                class: _class,
                type: type
            },
            mutation: gql`
          mutation ($id: String, $placeId: String, $traditional: Boolean, $instant: Boolean, $recommended: Boolean,$populated: Boolean, $best_seller: Boolean, $class: String, $type: String, $city_id: String, $stars: Int,$panel_user_id: Int, $code: String!, $name: String!, $overview: String, $meta_description: String, $meta_key: String, $lat: String!, $lng: String!) {
              saveAccommodationBasicData(id: $id, traditional: $traditional,  instant: $instant, recommended: $recommended, best_seller: $best_seller, populated: $populated, class: $class, type: $type, place_id: $placeId, city_id: $city_id, stars: $stars,panel_user_id: $panel_user_id, code: $code, name: $name, overview: $overview, meta_key: $meta_key, meta_description: $meta_description, lat: $lat, lng: $lng) {
                updatedAccommodation {
                  id
                  code
                  place {
                    meta_key
                    meta_description
                  }
                }
              }
            }
          `
        }).then(result => {
            return result.data.saveAccommodationBasicData.updatedAccommodation
        }).catch(error => {

            console.log(error)
        });


    }
}
const UpdateHotelAddress = (client, data, id = 0,) => {

    let address = data.get('address');

    return dispatch => {

        return client.mutate({
            variables: {
                address: address,
                id: id,
            },
            mutation: gql`
                mutation update($id: String!, $address: String!) {
                  updateAccommodationAddressData(id: $id, address_data: {address: $address}) {
                    updatedAccommodation {
                      id
                    }
                  }
                }
          `
        }).then(result => {
            return result.data.updateAccommodationAddressData.updatedAccommodation
        }).catch(error => {

            console.log(error)
        });


    }
}

const UpdateHotelPolicies = (client, data, id = 0,) => {

    let policies = data.get('policies');

    return dispatch => {

        return client.mutate({
            variables: {
                policies: policies,
                id: id,
            },
            mutation: gql`
               mutation update($id: String!, $policies: String) {
                   updateAccommodationCancellationConditions(id: $id, cancellation_conditions: {policies: $policies}) {
                    updatedAccommodation {
                       id
                      }
                      }
                }
          `
        }).then(result => {
            console.log(result)
            return result.data.updateAccommodationCancellationConditions.updatedAccommodation
        }).catch(error => {

            console.log(error)
        });


    }
}

const updateTerms = (client, data, id = 0,) => {

    let checkin = data.get('checkin');
    let checkout = data.get('checkout');
    let smoking = data.get('smoking');
    let pets = data.get('pets');
    let good_to_know = data.get('good_to_know');
    let payment = data.get('payment');
    let children = data.get('children');
    console.log(pets, smoking)

    return dispatch => {

        return client.mutate({
            variables: {
                id: id,
                checkin: checkin,
                checkout: checkout,
                smoking: smoking,
                pets: pets,
                good_to_know: good_to_know,
                payment: payment,
                children: children,
            },
            mutation: gql`
          mutation update($id: String!, $checkin: String, $checkout: String, $good_to_know: String,$payment: String, $children: String,$smoking: Boolean, $pets: Boolean) {
            updateAccommTermsData(id: $id, terms_data: {check_in: $checkin, checkout: $checkout,pets:$pets,smoking:$smoking,good_to_know:$good_to_know,payment:$payment,children:$children}) {
              updatedAccommodation {
                id
              }
            }
          }
          `
        }).then(result => {
            console.log(result)
            return result.data.updateAccommTermsData.updatedAccommodation
        }).catch(error => {

            console.log(error)
        });


    }
}

export default {
    getHotelList,
    UpdateHotel,
    updateTerms,
    UpdateHotelAddress,
    UpdateHotelPolicies,
    updateHotelRoom,
    updateHotelRoomRequest,
    RemovePrice,
    UpdateRoomPackages,
    updateRoomPrice,
    DeleteRoomPackage,
    DeleteRoom,
    DeleteHotel,
    getHotel,
    updateFacility,
    updateRoomFacility,
    getFacilities,
    getHotelRooms,
}