import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../../config";
import Room from "@material-ui/icons/Home";
import Remove from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

const styles = {
    ...stylesAlert,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {
    const [alert, setAlert] = React.useState(null);
    const [editId, setEditId] = React.useState(0);


    const {dispatch, client} = props;
    const classes = useStyles();

    const warningWithConfirmAndCancelMessage = (id) => {

        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Are you sure?"
                onConfirm={() => successDelete(id)}
                onCancel={() => cancelDetele()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        )
    };
    const successDelete = (id) => {
        deleteItem(id);

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >Your item has been deleted.</SweetAlert>
        );
    };
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Uploaded!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item has been saved.
            </SweetAlert>
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{display: "block", marginTop: "-100px"}}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item is safe :)
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        client.cache.reset()
        fetchData()
    };
    useEffect(() => {
        fetchData();

    }, props.loading);


    const fetchData = () => {
        let id = props.match.params.hotel_id;
        client.cache.reset()
        dispatch(HotelAction.getHotel(client, id));


    }


    const edit = (item) => {

        document.getElementById("name").value = item._original.name;
        document.getElementById("sleeps").value = item._original.sleeps;
        document.getElementById("count").value = item._original.count;
        document.getElementById("notes").value = item._original.notes;
        document.getElementById("more_info").value = item._original.more_info;
        setEditId(item.id)

    }

    const submit = (e) => {

        e.preventDefault();
        const {client} = props;
        let id = props.match.params.hotel_id;
        const frmUpload = document.getElementById('frmUpload')

        const fd = new FormData(e.target)
        fd.append('accommodation_id', id)

        dispatch(HotelAction.UpdateRoomPackages(client, fd, editId)).then(res => {
            frmUpload.reset()
            setEditId(0)

            successUpload()
        })
        return true;
    }


    const deleteItem = (id) => {

        const {client} = props;

        dispatch(HotelAction.DeleteRoomPackage(client, id))
    }

    const {data} = props;
    return (

        <GridContainer>
            {alert}

            <GridItem xs={12}>
                <Button href="#" className={classes.title} style={{fontSize: "1.2rem"}} color="transparent">
                    Room Packages of {data.name}
                </Button>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Room/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{
                            editId == 0 ? "Add New Room Packages" : "Edit Room Packages"
                        }</h4>

                    </CardHeader>
                    <CardBody>
                        <form id={"frmUpload"} onSubmit={(e) => submit(e)}>
                            <GridContainer>

                                <GridItem xs={12} sm={12} md={2}>
                                    <CustomInput

                                        id="name"
                                        labelText={"Room Package Name"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "name",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <CustomInput
                                        labelText={"Sleeps"}
                                        id="sleeps"

                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            name: "sleeps",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <CustomInput
                                        labelText={"Capacity"}
                                        id="count"

                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            name: "count",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText={"Notes"}
                                        id="notes"

                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "notes",
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    <CustomInput
                                        labelText={"More Info"}
                                        id="more_info"

                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "more_info",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <Button type="submit" color="success">Save</Button>

                                </GridItem>

                            </GridContainer>

                        </form>
                    </CardBody>
                </Card>
            </GridItem>

            {
                editId == 0 && <GridItem xs={12}>
                    <Card>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <Room/>
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Hotel Room Packages</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={data.RoomPackage}
                                filterable
                                pageSize={data.RoomPackage && data.RoomPackage.length}
                                columns={[

                                    {
                                        Header: "Id",
                                        sortable: false,
                                        accessor: "id"
                                    },

                                    {
                                        Header: "Name",
                                        sortable: false,
                                        accessor: "name"
                                    },

                                    {
                                        Header: "Actions",
                                        accessor: "id",
                                        sortable: false,
                                        filterable: false,
                                        Cell: row => (
                                            <div className="actions-right">
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Remove"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button color="danger"
                                                            onClick={b => warningWithConfirmAndCancelMessage(row.value)}
                                                            simple
                                                            justIcon>
                                                        <Remove className={classes.underChartIcons}/>
                                                    </Button>
                                                </Tooltip>
                                                {" "}
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Edit"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button color="warning"
                                                            onClick={(e) => edit(row.row)}
                                                            simple
                                                            justIcon>
                                                        <Edit className={classes.underChartIcons}/>
                                                    </Button>
                                                </Tooltip>
                                                {" "}
                                                <a
                                                    href={"/admin/hotel/rooms/" + row.value + "/" + row.row._original.accommodation_id}

                                                >
                                                    <Tooltip
                                                        id="tooltip-top"
                                                        title="Room Types"
                                                        placement="bottom"
                                                        classes={{tooltip: classes.tooltip}}
                                                    >

                                                        <Button
                                                            round
                                                            justIcon
                                                            simple
                                                            color="success"
                                                            className="edit"
                                                        >


                                                            <Room/>
                                                        </Button>
                                                    </Tooltip>
                                                </a>

                                                {" "}

                                            </div>
                                        )
                                    },
                                ]}

                                loading={props.loading}
                                showPaginationBottom={false}
                                className="-striped -highlight"
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            }

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)