export const prefix = "requests/";
export const GET_HOTELS_REQUESTS_REQUEST = prefix + 'GET_HOTELS_REQUESTS_REQUEST';
export const GET_HOTELS_REQUESTS_SUCCESS = prefix + 'GET_HOTELS_REQUESTS_SUCCESS';
export const GET_HOTELS_REQUESTS_FAILED = prefix + 'GET_HOTELS_REQUESTS_FAILED';


export const GET_HOTEL_REQUEST_REQUEST = prefix + 'GET_HOTEL_REQUEST_REQUEST';
export const GET_HOTEL_REQUEST_SUCCESS = prefix + 'GET_HOTEL_REQUEST_SUCCESS';
export const GET_HOTEL_REQUEST_FAILED = prefix + 'GET_HOTEL_REQUEST_FAILED';


export const GET_HOTEL_RESPONE_REQUEST = prefix + 'GET_HOTEL_RESPONSE_REQUEST';
export const GET_HOTEL_RESPONE_SUCCESS = prefix + 'GET_HOTEL_RESPONSE_SUCCESS';
export const GET_HOTEL_RESPONE_FAILED  = prefix + 'GET_HOTEL_RESPONSE_FAILED';

