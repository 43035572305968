import gql from 'graphql-tag';

import {
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILED,
    GET_PAYOUT_REQUEST,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_FAILED
} from "../types/Transactions";

const GetTransactions = (client, pagination = {per_page: 15, page: 1}, filters = {
    status: "", email: "", type: "", range: {from: "", to: ""}
}, promo_code = "") => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
      query{
        GetUserTransactions( pagination:{perPage:${pagination.per_page},page:${pagination.page}}, filters:{status:"${filters.status}"
        ,email:"${filters.email}",type:"${filters.type}",range: { from: "${filters.range.from}", to: "${filters.range.to}"}
        }, promo_code:"${promo_code}"
        ) {
          Items {
             status
             description
             email
             type
             promo_code
             authority
             foreign_id
             book_request_id
             created_at
             price
             persian_created_at
          }
          pagination {
            total
            current_page
            last_page
            per_page
            from
            to
          }
        }
       }
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_TRANSACTIONS_REQUEST}
        }

        function success(result) {
            return {
                type: GET_TRANSACTIONS_SUCCESS,
                payload: result.GetUserTransactions.Items,
                pagination: result.GetUserTransactions.pagination
            }
        }

        function failure(error) {
            return {type: GET_TRANSACTIONS_FAILED, error: {error}}
        }
    }
}
const GetPayoutRequests = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
              query{
                PayoutRequests{
                  euro
                  rials
                  payout
                  pending_payout
                  percent
                  user{
                    id
                    first_name
                    last_name
                    email      
                  }
                }
              }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_PAYOUT_REQUEST}
        }

        function success(result) {
            return {
                type: GET_PAYOUT_SUCCESS,
                payload: result.PayoutRequests,
            }
        }

        function failure(error) {
            return {type: GET_PAYOUT_FAILED, error: {error}}
        }
    }
}

export default {
    GetTransactions,
    GetPayoutRequests,

}