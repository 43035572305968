import gql from 'graphql-tag';

import {
    GET_PROMO_CODE_LIST_REQUEST,
    GET_PROMO_CODE_LIST_SUCCESS,
    GET_PROMO_CODE_LIST_FAILED,
    GET_PROMO_CODE_REQUEST,
    GET_PROMO_CODE_SUCCESS,
    GET_PROMO_CODE_FAILED

} from "../../types/PromoCode";

const GetPromoCodes = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query   {
                    GetPromoCodes   {
                       price
                       id
                       max_price
                       code
                       percent
                       infinite
                       used
                       active
                       type
                       commission
                       promo_codeable_id
                       promo_codeable_type
                       from
                       to
                       panel_user_id
                       created_at
                       jalali_created_at
                     }                 
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_PROMO_CODE_LIST_REQUEST}
        }

        function success(result) {
            return {
                type: GET_PROMO_CODE_LIST_SUCCESS,
                payload: result.GetPromoCodes,
            }
        }

        function failure(error) {
            console.log(error)

            return {type: GET_PROMO_CODE_LIST_FAILED, error: {error}}
        }
    }
}
const GetPromoCode = (client, id) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query   {
                    GetPromoCode(id:${id})  {
                       price
                       id
                       max_price
                       code
                       percent
                       infinite
                       used
                       active
                       type
                       commission
                       promo_codeable_id
                       promo_codeable_type
                       from
                       to
                       panel_user_id
                       created_at
                       jalali_created_at
                     }                 
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_PROMO_CODE_REQUEST}
        }

        function success(result) {
            return {
                type: GET_PROMO_CODE_SUCCESS,
                payload: result.GetPromoCode,
            }
        }

        function failure(error) {
            console.log(error)

            return {type: GET_PROMO_CODE_FAILED, error: {error}}
        }
    }
}

const addNewPromoCode = (client, data) => {

    let code = data.get('code')
    let price = data.get('price')
    let max_price = data.get('max_price')
    let percent = data.get('percent')
    let commission = data.get('commission')
    let infinite = data.get('infinite')
    let from = data.get('from')
    let to = data.get('to')
    let panel_user_id = data.get('panel_user_id')
    let type = data.get('type')


    return dispatch => {
        return client.mutate({
            variables: {
                code: code,
                price: price,
                max_price: max_price,
                percent: percent,
                commission: commission,
                infinite: infinite,
                from: from,
                to: to,
                panel_user_id: panel_user_id,
                type: type,
            },
            mutation: gql`
                    mutation update(
                      $code: String!
                      $price: Float
                      $max_price: Float
                      $percent: Float
                      $commission: Float
                      $infinite: Boolean
                      $from: String
                      $to: String
                      $type: String
                      $panel_user_id: Int
                    ) {
                      UpdatePromoCode(
                        code: $code
                        price: $price
                        max_price: $max_price
                        percent: $percent
                        commission: $commission
                        infinite: $infinite
                        from: $from
                        to: $to
                        type: $type
                        panel_user_id: $panel_user_id
                      ) {
                        code
                      }
                    }
                 `
        }).then(res => {
            return res.data.UpdatePromoCode


        }).catch(error => {


            console.log(error)
        })

    }
};

const updatePromoCode = (client, data) => {

    let percent = data.get('percent')
    let commission = data.get('commission')
    let id = data.get('id')
    let code = data.get('code')


    return dispatch => {
        return client.mutate({
            variables: {

                percent: percent,
                code: code,
                commission: commission,
                id: id,
            },
            mutation: gql`
                    mutation update(
                      $percent: Float
                      $commission: Float
                      $id: Int
                      $code: String!
                    ) {
                      UpdatePromoCode(
                        code: $code
                        percent: $percent
                        commission: $commission
                        id: $id
                       
                      ) {
                        code
                      }
                    }
                 `
        }).then(res => {
            console.log(res)
            return res.data.UpdatePromoCode


        }).catch(error => {


            console.log(error)
        })

    }
};
export default {
    GetPromoCodes,
    GetPromoCode,
    addNewPromoCode,
    updatePromoCode,
}