import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelRequestAction from "actions/requests/HotelRequestAction";
import CardFooter from "components/Card/CardFooter.js";
import Update from "@material-ui/icons/Update";
import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Utility from "Utils/helper.js";
import validationStyles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";


import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from "variables/charts";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Check from "@material-ui/core/SvgIcon/SvgIcon";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const styles = {
    ...style,
    ...stylesAlert,
    ...validationStyles,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {


    const {dispatch, client} = props;


    const [alerts, setAlert] = React.useState(null);
    const [expiry, setExpiry] = React.useState(false);
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={"Saved!"}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your Request has been saved successfully.
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        client.cache.reset()
    };
    const {request, loading} = props;

    const handleToggle = value => {
        setExpiry(!expiry);
    };
    useEffect(() => {
        let token = props.match.params.token
        dispatch(HotelRequestAction.GetHotelRequest(client, token)).then(res => {
            setExpiry(request.book_response && request.book_response.is_day)
        });

    }, props.loading);

    const classes = useStyles();

    const submit = (e) => {
        e.preventDefault()

        const fd = new FormData(e.target)

        fd.append("hotel_request_id", request.id)
        fd.append("is_day", expiry ? "true" : "false")
        if (request.book_response)
            fd.append("id", request.book_response.id)


        dispatch(HotelRequestAction.UpdateRequestResponse(client, fd))

    }
    const onChange = (status) => {

        HotelRequestAction.UpdateRequestStatus(client, request.id, status).then(res => {
            successUpload()
        })

    }
    const calcDiscountPrice = () => {

        let price = request.book_response ? request.book_response.price : request.final_price;

        if (request.promo_code) {

            let promo = request.promo_code;


            if (promo.price != 0 && promo != null) {

                return promo.price

            } else {

                let discount = price * promo.percent / 100
                if (discount > promo.max_price)
                    return promo.max_price

                return discount

            }


        }

        return 0


    }

    if (loading)
        return "Loading..."
    return (

        <GridContainer>
            {alerts}
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardHeader color="info">

                        <h4 className={classes.cardTitle}>Booking
                            Details of {request.room.accommodation.name} Hotel, {request.room.name}</h4>

                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Room Name:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.room.name}
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Cost:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {Utility.formatter.format(request.cost)} Per Room/Person
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Count:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.count} Room/Person
                                </FormLabel>
                            </GridItem>
                            <hr style={{
                                width: "100%"
                            }}/>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Check-in:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.check_in}
                                </FormLabel>
                            </GridItem>


                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Checkout:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.check_out}
                                </FormLabel>
                            </GridItem>


                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Jalali Check-in:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.persian_check_in}
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Jalali Checkout:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.persian_check_out}
                                </FormLabel>
                            </GridItem>
                            <hr style={{
                                width: "100%"
                            }}/>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Amount:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {Utility.formatter.format(request.final_price)}
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Final Amount:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {
                                        request.book_response ? Utility.formatter.format(request.book_response.price - calcDiscountPrice()) : Utility.formatter.format(request.final_price - calcDiscountPrice())
                                    }

                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Promo Code:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {
                                        request.promo_code ? request.promo_code.code : "Not Use"
                                    }

                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Status:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {
                                        request.status
                                    }

                                </FormLabel>
                            </GridItem>


                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>


            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardHeader color="info">

                        <h4 className={classes.cardTitle}>Passenger Information</h4>

                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    First Name:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.first_name}
                                </FormLabel>
                            </GridItem>


                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Last Name:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.last_name}
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Mobile:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.mobile}
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Email:
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.email}
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Nationality:
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.nationality}
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Country:
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.country}
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Passport Number:
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={1}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.passport_number}
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Special Request:
                                </FormLabel>
                            </GridItem>

                            <GridItem xs={12} sm={3}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {request.special_request}
                                </FormLabel>
                            </GridItem>


                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>

                    <CardHeader color="info">

                        <h4 className={classes.cardTitle}>Response to Booking</h4>

                    </CardHeader>
                    <CardBody>

                        <GridContainer>

                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Amount
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={9}>
                                <div style={{paddingTop: 29}}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            value={request.status}
                                            onChange={e => onChange(e.target.value)}
                                            inputProps={{
                                                name: 'type',
                                            }}
                                        >
                                            <MenuItem value="waiting">Waiting</MenuItem>
                                            <MenuItem value="pending">Pending</MenuItem>
                                            <MenuItem value="accepted">Accepted</MenuItem>
                                            <MenuItem value="rejected">Rejected</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <form onSubmit={(e) => submit(e)}>
                        <CardHeader color="info">

                            <h4 className={classes.cardTitle}>Response to Booking</h4>

                        </CardHeader>
                        <CardBody>

                            <GridContainer>

                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Amount
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            step: "any",
                                            type: "number",
                                            name: "price",
                                            defaultValue: request.book_response && request.book_response.price

                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Expiry
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            step: "any",
                                            name: "expire_time",
                                            defaultValue: request.book_response && request.book_response.expire_time
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={2}>
                                    <div className={classes.labelHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={request.book_response && request.book_response.is_day}
                                                    onClick={() => setExpiry(!expiry)}

                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot
                                            }}
                                            label="Expiry Time is Day"
                                        />
                                    </div>

                                </GridItem>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Description
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "description",
                                            defaultValue: request.book_response && request.book_response.description
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <GridContainer justify="flex-end">
                                <GridItem xs={12} sm={12} md={1}>
                                    <Button type={"submit"} color="rose">Save</Button>
                                </GridItem>
                            </GridContainer>
                        </CardFooter>
                    </form>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    request: state.requests.hotel_request,
    loading: state.requests.loading,
    error: state.requests.error,
})

const mapDispatchToProps = dispatch => ({
    HotelRequestAction: bindActionCreators({HotelRequestAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)