import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/User/UserAction";
import CardFooter from "components/Card/CardFooter.js";
import Update from "@material-ui/icons/Update";
import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Utility from "Utils/helper.js";
import validationStyles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OthersAction from "actions/Others";


import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from "variables/charts";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
    ...style,
    ...stylesAlert,
    ...validationStyles,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {
    const [maxValue, setmaxValue] = React.useState("");
    const [maxValueState, setmaxValueState] = React.useState("");
    const [request, setRequest] = React.useState(false);

    const [maxRialValue, setmaxRialValue] = React.useState("");
    const [maxRialValueState, setmaxRialValueState] = React.useState("");
    const [requestpayout, setRequestpayout] = React.useState(false);
    const [commission, setCommission] = React.useState(0);


    const {dispatch, client} = props;


    const rangeClick = () => {
        if (maxValue === "") {
            alert("Please enter a value.")

            return false
        }
        if (maxValueState === "error") {
            alert("Please enter a value less or equal to your balance.")
        } else {
            setRequest(true)
            dispatch(UserAction.RequestExchange(client, maxValue)).then(res => {
                setRequest(false)

                if (res == 1) {
                    successUpload()
                }

            });
        }

    };
    const payoutRequest = () => {
        if (maxRialValue === "") {
            alert("Please enter a value.")

            return false
        }
        if (maxRialValueState === "error") {
            alert("Please enter a value less or equal to your balance.")
        } else {
            setRequestpayout(true)
            dispatch(UserAction.RequestPayout(client, maxRialValue)).then(res => {

                if (res == 1) {
                    successUpload("Your Request Has Been Received.", "Payout Request")
                }

            });
        }

    };
    const [alerts, setAlert] = React.useState(null);
    const [rate, setRate] = React.useState(0);
    const successUpload = (desc = "Your balance has been updated.", title = "Exchanged!") => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {desc}
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        setmaxValue('');
        client.cache.reset()
        dispatch(UserAction.GetWallet(client));
    };
    useEffect(() => {
        dispatch(UserAction.GetWallet(client));
        OthersAction.getExchangeRate(client).then(res => {
            setRate(res)
        });
    }, props.loading);

    const classes = useStyles();
    const {wallet, loading, user_details} = props;

    const getPercent = () => {

        let percent = wallet.percent
        let rials = wallet.rials
        let m = percent / 100;
        let y = (m + 1);
        return Math.floor(rials * m / y);
    };

    if (loading)
        return "Loading..."
    return (

        <GridContainer>
            {alerts}
            <GridContainer xs={12} sm={6} md={12} lg={12}>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                    <Card>
                        <CardHeader color="rose" stats icon>
                            <CardIcon color="rose">
                                <i className="fas fa-euro-sign"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}>Euros</p>
                            <h3 style={{color: "#000"}}
                                className={classes.cardTitle}>{Utility.formatter.format(wallet.euro)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Update/>
                                Just Updated
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <i className="fas ">R</i>
                            </CardIcon>
                            <p className={classes.cardCategory}>Rials</p>
                            <h3 style={{color: "#000"}}
                                className={classes.cardTitle}>{Utility.rialFormatter.format(Math.abs(wallet.rials))}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Update/>
                                Just Updated
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <i className="fas fa-credit-card"></i>
                            </CardIcon>
                            <p className={classes.cardCategory}>Payout Requested Amount</p>
                            <h3 style={{color: "#000"}}
                                className={classes.cardTitle}>{Utility.rialFormatter.format(wallet.payout)}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Update/>
                                {
                                    !wallet.pending_payout ? "Payout is clear" : "Payout is Pending"
                                }

                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Card>
                        <CardHeader color="rose" text>
                            <CardText color="rose">
                                <h4 className={classes.cardTitle}>Exchange Euro To Rials</h4>
                            </CardText>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>


                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Value
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={9}>
                                        <CustomInput
                                            success={maxValueState === "success"}
                                            error={maxValueState === "error"}
                                            id="maxvalue"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: wallet.euro <= 1,
                                                onChange: event => {
                                                    if (
                                                        event.target.value <= wallet.euro
                                                    ) {
                                                        setmaxValueState("success");
                                                    } else {
                                                        setmaxValueState("error");
                                                    }
                                                    setmaxValue(event.target.value);
                                                },
                                                type: "number",
                                                step: "any",
                                                endAdornment:
                                                    maxValueState === "error" ? (
                                                        <InputAdornment position="end">
                                                            <Close className={classes.danger}/>
                                                        </InputAdornment>
                                                    ) : (
                                                        undefined
                                                    )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Exchange Amount
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            {Utility.rialFormatter.format(rate * maxValue)}
                                        </FormLabel>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                            {
                                request ? <Button color="warning">Sending</Button>
                                    : <Button color="rose" onClick={rangeClick}>
                                        Request Exchange
                                    </Button>
                            }

                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Card>
                        <CardHeader color="info" text>
                            <CardText color="info">
                                <h4 className={classes.cardTitle}>Request Payout</h4>
                            </CardText>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>


                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Value
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={9}>
                                        <CustomInput
                                            success={maxRialValueState === "success"}
                                            error={maxRialValueState === "error"}
                                            id="maxRialValue"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: wallet.rials <= 1 || wallet.pending_payout,
                                                onChange: event => {

                                                    let rials = 0;
                                                    if (user_details.type != "reseller")
                                                        rials = wallet.rials - getPercent()
                                                    else
                                                        rials = wallet.rials

                                                    if (
                                                        event.target.value <= rials
                                                    ) {
                                                        setmaxRialValueState("success");
                                                    } else {
                                                        setmaxRialValueState("error");
                                                    }
                                                    setmaxRialValue(event.target.value);
                                                    if (user_details.type != "reseller")
                                                        setCommission(Math.floor(((event.target.value) * wallet.percent) / 100));
                                                    else
                                                        setCommission(0);

                                                },
                                                type: "number",
                                                step: "any",
                                                endAdornment:
                                                    maxRialValueState === "error" ? (
                                                        <InputAdornment position="end">
                                                            <Close className={classes.danger}/>
                                                        </InputAdornment>
                                                    ) : (
                                                        undefined
                                                    )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Available Amount:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>

                                            {
                                                user_details.type != "reseller" ? (Utility.rialFormatter.format(wallet.rials - getPercent())) : Utility.rialFormatter.format(wallet.rials)
                                            }

                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            Commission:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormLabel className={classes.labelHorizontal}>
                                            {Utility.rialFormatter.format(commission)}
                                        </FormLabel>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                            {
                                requestpayout || wallet.pending_payout ? <Button color="warning">Pending</Button>
                                    : <Button color="info" onClick={payoutRequest}>
                                        Request Payout
                                    </Button>
                            }

                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*<GridItem xs={12} sm={12} md={12} lg={6}>*/}

            {/*<Card chart className={classes.cardHover}>*/}
            {/*<CardHeader color="rose" className={classes.cardHeaderHover}>*/}
            {/*<ChartistGraph*/}
            {/*className="ct-chart-white-colors"*/}
            {/*data={emailsSubscriptionChart.data}*/}
            {/*type="Line"*/}
            {/*options={emailsSubscriptionChart.options}*/}
            {/*listener={emailsSubscriptionChart.animation}*/}
            {/*/>*/}
            {/*</CardHeader>*/}
            {/*<CardBody>*/}
            {/*<div className={classes.cardHoverUnder}>*/}
            {/*<Tooltip*/}
            {/*id="tooltip-top"*/}
            {/*title="Refresh"*/}
            {/*placement="bottom"*/}
            {/*classes={{tooltip: classes.tooltip}}*/}
            {/*>*/}
            {/*<Button simple color="info" justIcon>*/}
            {/*<Refresh className={classes.underChartIcons}/>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
            {/*<Tooltip*/}
            {/*id="tooltip-top"*/}
            {/*title="Change Date"*/}
            {/*placement="bottom"*/}
            {/*classes={{tooltip: classes.tooltip}}*/}
            {/*>*/}
            {/*<Button color="transparent" simple justIcon>*/}
            {/*<Edit className={classes.underChartIcons}/>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
            {/*</div>*/}
            {/*<h4 className={classes.cardTitle}>Wallet Euro Transactions</h4>*/}
            {/*<p className={classes.cardCategory}>This chart shows your euro transactions visually</p>*/}
            {/*</CardBody>*/}
            {/*<CardFooter chart>*/}
            {/*<div className={classes.stats}>*/}
            {/*<AccessTime/> Wallet Transactions*/}
            {/*</div>*/}
            {/*</CardFooter>*/}
            {/*</Card>*/}
            {/*</GridItem>*/}
            {/*<GridItem xs={12} sm={12} md={12} lg={6}>*/}

            {/*<Card chart className={classes.cardHover}>*/}
            {/*<CardHeader color="info" className={classes.cardHeaderHover}>*/}
            {/*<ChartistGraph*/}
            {/*className="ct-chart-white-colors"*/}
            {/*data={emailsSubscriptionChart.data}*/}
            {/*type="Line"*/}
            {/*options={emailsSubscriptionChart.options}*/}
            {/*listener={emailsSubscriptionChart.animation}*/}
            {/*/>*/}
            {/*</CardHeader>*/}
            {/*<CardBody>*/}
            {/*<div className={classes.cardHoverUnder}>*/}
            {/*<Tooltip*/}
            {/*id="tooltip-top"*/}
            {/*title="Refresh"*/}
            {/*placement="bottom"*/}
            {/*classes={{tooltip: classes.tooltip}}*/}
            {/*>*/}
            {/*<Button simple color="info" justIcon>*/}
            {/*<Refresh className={classes.underChartIcons}/>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
            {/*<Tooltip*/}
            {/*id="tooltip-top"*/}
            {/*title="Change Date"*/}
            {/*placement="bottom"*/}
            {/*classes={{tooltip: classes.tooltip}}*/}
            {/*>*/}
            {/*<Button color="transparent" simple justIcon>*/}
            {/*<Edit className={classes.underChartIcons}/>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
            {/*</div>*/}
            {/*<h4 className={classes.cardTitle}>Wallet Rial Transactions</h4>*/}
            {/*<p className={classes.cardCategory}>This chart shows your rials transactions visually</p>*/}
            {/*</CardBody>*/}
            {/*<CardFooter chart>*/}
            {/*<div className={classes.stats}>*/}
            {/*<AccessTime/> Wallet Transactions*/}
            {/*</div>*/}
            {/*</CardFooter>*/}
            {/*</Card>*/}
            {/*</GridItem>*/}
        </GridContainer>
    );
}

const mapStateToProps = state => ({
    wallet: state.user.wallet,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)