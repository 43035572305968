import Login from "views/Pages/LoginPage";
import DashboardIcon from "@material-ui/icons/Dashboard";

var Routes = [
    {
        path: "/login-page",
        name: "Login",
        rtlName: "ورود",
        icon: DashboardIcon,
        component: Login,
        layout: "/auth"
    },
];
export default Routes;
