import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Photo from "@material-ui/icons/Photo";
import Facilitites from "@material-ui/icons/RoomService";
import Room from "@material-ui/icons/Home";
import Book from "@material-ui/icons/Book";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import Tooltip from "@material-ui/core/Tooltip";
import {
    tooltip,
} from "assets/jss/material-dashboard-pro-react.js";
import helper from 'Utils/helper.js';
import Remove from "@material-ui/icons/Delete";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
    ...stylesAlert,
    tooltip,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function HotelList(props) {
    const [alert, setAlert] = React.useState(null);

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        client.cache.reset()
        dispatch(HotelAction.getHotelList(client, pagination, filters));
    }
    const classes = useStyles();
    const {pagination, data, loading, user_details} = props;
    const [filters, setFilters] = React.useState({
        name: "",
        city: "",
    });
    const onFilteredChange = (column, value) => {

        if (column.length === 0)
            setFilters({
                name: "",
                city: "",
            })

        column.map(e => {
            setFilters({
                ...filters,
                [e.id]: e.value
            })
        })

    }


    const warningWithConfirmAndCancelMessage = (id) => {

        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Are you sure?"
                onConfirm={() => successDelete(id)}
                onCancel={() => cancelDetele()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this imaginary file!
            </SweetAlert>
        )
    };
    const successDelete = (id) => {
        deleteItem(id);

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >Your item has been deleted.</SweetAlert>
        );
    };
    const successUpload = () => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Uploaded!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item has been saved.
            </SweetAlert>
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{display: "block", marginTop: "-100px"}}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Your item is safe :)
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        client.cache.reset()
        fetchMore();
    };

    const deleteItem = (id) => {

        const {client} = props;

        dispatch(HotelAction.DeleteHotel(client, id))
    }
    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Hotel/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Hotels</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={2}>
                                <Button onClick={e => fetchMore()} color="primary">Apply Filters</Button>
                            </GridItem>
                            {
                                (helper.isAdmin(user_details.type) ||
                                    helper.hasPermission("add_hotel_permission", user_details.roles)) &&
                                <GridItem xs={12} sm={12} md={2}>
                                    <a href="/admin/hotel/add-new-hotel"><Button color="info">Add New Hotel</Button></a>
                                </GridItem>
                            }

                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTableCustom
                            columns={[
                                {
                                    Header: "ID",
                                    sortable: false,
                                    accessor: "id"
                                },
                                {
                                    Header: "Hotel Name",
                                    sortable: false,
                                    accessor: "name"
                                },

                                {
                                    Header: "City",
                                    sortable: false,
                                    accessor: "place.city.name",
                                    id: "city"
                                },

                                {
                                    Header: "Actions",
                                    accessor: "id",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">
                                            <a
                                                href={"/admin/hotel/book-history/" + row.value}

                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Booking History"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="rose"
                                                        className="edit"
                                                    >


                                                        <Book/>
                                                    </Button>
                                                </Tooltip>

                                            </a>
                                            {" "}

                                            <a
                                                href={"/admin/hotel/edit/" + row.value}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Edit"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="warning"
                                                        className="edit"
                                                    >


                                                        <Edit/>
                                                    </Button>
                                                </Tooltip>

                                            </a>
                                            {" "}
                                            <a
                                                href={"/admin/hotel/photos/" + row.value}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Photos"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="info"
                                                        className="edit"
                                                    >


                                                        <Photo/>
                                                    </Button>
                                                </Tooltip>
                                            </a>

                                            {" "}
                                            <a
                                                href={"/admin/hotel/facilities/" + row.value}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Facilities"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="primary"
                                                        className="edit"
                                                    >


                                                        <Facilitites/>
                                                    </Button>
                                                </Tooltip>
                                            </a>

                                            {" "} <a
                                            href={"/admin/hotel/packages/" + row.value}
                                        >
                                            <Tooltip
                                                id="tooltip-top"
                                                title="Rooms"
                                                placement="bottom"
                                                classes={{tooltip: classes.tooltip}}
                                            >

                                                <Button
                                                    round
                                                    justIcon
                                                    simple
                                                    color="success"
                                                    className="edit"
                                                >


                                                    <Room/>
                                                </Button>
                                            </Tooltip>
                                        </a>

                                            {" "}
                                            <a
                                                href={"/admin/hotel/room-pricing/" + row.value}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Room Pricing"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >

                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="danger"
                                                        className="edit"
                                                    >


                                                        <EuroSymbol/>
                                                    </Button>
                                                </Tooltip>
                                            </a>

                                            {" "}

                                            <Tooltip
                                                id="tooltip-top"
                                                title="Remove Hotel"
                                                placement="bottom"
                                                classes={{tooltip: classes.tooltip}}
                                            >

                                                <Button
                                                    round
                                                    justIcon
                                                    simple
                                                    onClick={b => warningWithConfirmAndCancelMessage(row.value)}
                                                    color="danger"
                                                    className="edit"
                                                >


                                                    <Remove/>
                                                </Button>
                                            </Tooltip>


                                            {" "}
                                        </div>
                                    )
                                },
                            ]}
                            pagination={pagination}
                            fetchMore={fetchMore}
                            loading={loading}
                            onFilteredChange={onFilteredChange}
                            data={data}
                        />

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.data,
    pagination: state.hotel.pagination,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HotelList)