import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import ContactMailOutlined from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/auth/UserAction";
import PromoCode from "actions/PromoCode/PromoCodeAction";
import Constants from "Utils/constatns.js";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './style.css';
import helper from 'Utils/helper.js';

const useStyles = makeStyles(styles);
const useStyle = makeStyles(theme => ({}));

function UserInfo(props) {
    const classe = useStyle();

    const [setctedType, setSelectedType] = React.useState("all");
    const [fromDate, setFromDate] = React.useState();
    const [toDate, setToDate] = React.useState();
    const [selectedUser, setSelecteduser] = React.useState(null);
     const [infinite, isInfinite] = React.useState(false);

    const {dispatch, client} = props;

    const {user_details, users} = props;

    useEffect(() => {
        if (helper.isAdmin(user_details.type) ||
            helper.hasPermission("hotel_special_items", user_details.roles))
            dispatch(UserAction.GetUserList(client, {per_page: 1000, page: 1}));


    }, props.loading);
    const renderUsers = (key) => {
        let component = (
            key.map(item => {
                return <MenuItem value={item.id}>{item.first_name + " " + item.last_name}</MenuItem>
            })
        );
        return component;
    }

    const updateUserBasicData = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        fd.append("infinite", infinite ? "1" : "0")
        fd.append("from", fromDate)
        fd.append("to", toDate)
        dispatch(PromoCode.addNewPromoCode(client, fd)).then(res => {

            if (res.code)
                window.location.href = "/admin/promo-codes/"
        });

    };
    const handleChangeFromDate = (date) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!

        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        setFromDate(today)
    };
    const handleChangeToDate = (date) => {

        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!

        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        setToDate(today)
    };

    const classes = useStyles();

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PersonOutline/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Add Promo Code</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserBasicData(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Code
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput

                                        id="code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "code",
                                            required: "required",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount Price
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="price"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "price",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount Percent
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="percent"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "percent",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Commission Percent
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="commission"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "commission",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Maximum Price of Discount
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="max_price"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            required: "required",
                                            name: "max_price",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount Date Range From
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <DayPickerInput
                                        onDayChange={handleChangeFromDate}/>

                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount Date Range To
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <DayPickerInput
                                        name={"to"}
                                        onDayChange={handleChangeToDate}/>

                                </GridItem>
                            </GridContainer>
                            <GridContainer md={12}>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>User</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <div style={{paddingTop: 29}}>
                                        <div className={classe.formControl}>
                                            <Select
                                                value={selectedUser}
                                                onChange={e => setSelecteduser(e.target.value)}
                                                inputProps={{
                                                    name: 'panel_user_id',
                                                }}
                                            >
                                                <MenuItem value={null}>No User</MenuItem>
                                                {renderUsers(users)}
                                            </Select>
                                        </div>
                                    </div>

                                </GridItem>

                            </GridContainer>
                            <GridContainer md={12}>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Type</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <div style={{paddingTop: 29}}>
                                        <div className={classe.formControl}>
                                            <Select
                                                value={setctedType}
                                                onChange={e => setSelectedType(e.target.value)}
                                                inputProps={{
                                                    name: 'type',
                                                }}
                                            >
                                                <MenuItem value={"all"}>All Services</MenuItem>
                                                <MenuItem value={"hotel"}>Hotels</MenuItem>
                                                <MenuItem value={"5star"}>5star Hotels</MenuItem>
                                                <MenuItem value={"4star"}>4star Hotels</MenuItem>
                                                <MenuItem value={"3star"}>3star Hotels</MenuItem>
                                                <MenuItem value={"2star"}>2star Hotels</MenuItem>
                                                <MenuItem value={"1star"}>1star Hotels</MenuItem>
                                                <MenuItem value={"hostel"}>Hostels</MenuItem>
                                                <MenuItem value={"boutique_hotel"}>Boutique Hotels</MenuItem>
                                                <MenuItem value={"guesthouse_ecolodge"}>Guesthouse & Ecolodge</MenuItem>
                                                <MenuItem value={"tours"}>Tours & Things to Do</MenuItem>
                                                <MenuItem value={"bus"}>Bus</MenuItem>
                                                <MenuItem value={"visa"}>Visa</MenuItem>
                                                <MenuItem value={"insurance"}>Insurance</MenuItem>
                                                <MenuItem value={"additional"}>Additional Services</MenuItem>
                                            </Select>
                                        </div>
                                    </div>

                                </GridItem>

                            </GridContainer>
                            <GridContainer md={8} justify="flex-end">
                                <GridItem xs={12} sm={12} md={8}>
                                    <div className={classes.checkboxAndRadio}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onClick={() => isInfinite(!infinite)}

                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon}/>
                                                    }
                                                    icon={<Check className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}

                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot
                                            }}
                                            label="Is Code Infinite"
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <Button type={"submit"} color="rose">Submit</Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    users: state.user.data,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo)