import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import ContactMailOutlined from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/auth/UserAction";
import PromoCode from "actions/PromoCode/PromoCodeAction";
import Constants from "Utils/constatns.js";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './style.css';
import helper from 'Utils/helper.js';
import promoCodes from "../../reducers/PromoCode";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(theme => ({}));

function UserInfo(props) {
    const classe = useStyle();

    const [Percent, setPercent] = React.useState(0);
    const [fullPercent, setFullPercent] = React.useState(0);
    const [Commission, setCommission] = React.useState(0);
    const [Discount, setDiscount] = React.useState(0);
    const [changed, setChanged] = React.useState(false);

    const {dispatch, client} = props;

    const {user_details, promo_code} = props;


    useEffect(() => {
        let promo_code_id = props.match.params.promo_code_id
        dispatch(PromoCode.GetPromoCode(client, promo_code_id));

    }, props.loading);

    useEffect(() => {

        if (!props.loading && !changed) {


            setPercent(parseInt(promo_code.commission) + parseInt(promo_code.percent))
            setCommission(parseInt(promo_code.commission))
            setDiscount(parseInt(promo_code.percent))

            let per = parseInt(promo_code.percent) * 100 / (parseInt(promo_code.commission) + parseInt(promo_code.percent))
            setFullPercent(per)

             setChanged(true)

        }

    });


    const updateUserBasicData = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        fd.append("id", props.match.params.promo_code_id);
        fd.append("code", promo_code.code);
        fd.append("percent", Discount);

        dispatch(PromoCode.updatePromoCode(client, fd)).then(res => {

            if (res.code)
                window.location.href = "/admin/promo-codes/"
        });

    };

    const handleChangeCommission = (e) => {

        let per = (Percent - e)


        if (e >= 0 && e <= Percent) {


            setCommission(e)
            setDiscount(per)
            setFullPercent(per * 100 / Percent)

        }

        if (helper.isAdmin(user_details.type) ||
            helper.hasPermission("edit_commissions_percent", user_details.roles)) {
            setCommission(e)
            setDiscount(0)
            setFullPercent(0)
        }


    };

    const handleChangePercent = (e) => {


        let per = (Percent * e) / 100


        if (e >= 0 && e <= 100) {
            setCommission(Percent - per)
            setDiscount(per)
            setFullPercent(e)

        }


    };

    const classes = useStyles();

    if (props.loading)
        return <div>Loading...</div>;

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
                <Card>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <PersonOutline/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Edit Promo Code Commission and Discount
                            | <b>{promo_code.code}</b></h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserBasicData(e)}>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Your Commission %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="commission"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            step: "0.01",
                                            name: "commission",
                                            onChange: event => {
                                                handleChangeCommission(event.target.value)
                                            },
                                            value: Commission

                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount of Commission %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <CustomInput
                                        id="percent"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            onChange: event => {
                                                handleChangePercent(event.target.value)
                                            },
                                            value: fullPercent
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <hr/>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        All of Your Commission %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>{Percent}</FormLabel>
                                </GridItem>

                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Discount for Customers %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>{Discount}</FormLabel>
                                </GridItem>

                            </GridContainer>


                            <Button type={"submit"} color="warning">Save</Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    promo_code: state.promoCodes.promo_code,
    loading: state.promoCodes.loading,
    error: state.promoCodes.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo)