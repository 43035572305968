import {combineReducers} from 'redux';
import login from './login';
import hotel from './hotel';
import user from './user';
import requests from './requests';
import transaction from './Transactions';
import promoCodes from './PromoCode';

export default function createRootReducer({apolloClient}) {
    return combineReducers({
        login,
        user,
        hotel,
        requests,
        transaction,
        promoCodes,
    });
}