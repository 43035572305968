import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelRequestAction from "actions/requests/HotelRequestAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "config";
import List from "@material-ui/icons/List";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Photo from "@material-ui/core/SvgIcon/SvgIcon";
import Utility from "Utils/helper.js";
import requests from "../../../reducers/requests";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

function Index(props) {

    const {dispatch, client} = props;

    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = (pagination = {per_page: 20, page: 1}) => {
        dispatch(HotelRequestAction.GetHotelRequests(client, pagination, "waiting"));
    }
    const classes = useStyles();
    const {pagination, data, loading} = props;
    return (

        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <List/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Hotel Requests</h4>
                    </CardHeader>
                    <CardBody>
                        <ReactTableCustom
                            columns={[
                                {
                                    Header: "Hotel Name",
                                    sortable: false,
                                    accessor: "room.accommodation.name"
                                },

                                {
                                    Header: "Room Name",
                                    sortable: false,
                                    accessor: "room.name"
                                },

                                {
                                    Header: "Check Data",
                                    sortable: false,
                                    accessor: "check_in",
                                    Cell: row => (
                                        <div>
                                            {
                                                row.value + " => " + row.original.check_out
                                            }
                                        </div>
                                    )
                                },

                                {
                                    Header: "Jalali dates",
                                    sortable: false,
                                    accessor: "persian_check_in",
                                    Cell: row => (
                                        <div>
                                            {
                                                row.value + " => " + row.original.persian_check_out
                                            }
                                        </div>
                                    )
                                },

                                {
                                    Header: "Amount",
                                    sortable: false,
                                    accessor: "final_price",
                                    Cell: row => (
                                        <div>
                                            {

                                                Utility.formatter.format(row.value)
                                            }
                                        </div>
                                    )
                                },
                                //
                                // {
                                //     Header: "Final Amount",
                                //     sortable: false,
                                //     accessor: "book_response",
                                //     Cell: row => (
                                //         row.value && row.value.price
                                //
                                //     )
                                // },
                                {
                                    Header: 'Request Date',
                                    sortable: false,
                                    accessor: 'created_at',
                                },

                                {
                                    Header: "Jalali Date",
                                    sortable: false,
                                    accessor: "persian_created_at"
                                },
                                {
                                    Header: "Actions",
                                    accessor: "token",
                                    sortable: false,
                                    filterable: false,
                                    Cell: row => (
                                        <div className="actions-right">


                                            <NavLink
                                                to={"/admin/hotel-request-details/" + row.value}
                                                target={"_blank"}
                                            >
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Details"
                                                    placement="bottom"
                                                    classes={{tooltip: classes.tooltip}}
                                                >
                                                    <Button
                                                        round
                                                        justIcon
                                                        simple
                                                        color="info"
                                                        className="edit"
                                                    >


                                                        <List/>
                                                    </Button>
                                                </Tooltip>

                                            </NavLink>
                                            {" "}

                                        </div>
                                    )
                                }


                            ]}
                            pagination={pagination}
                            fetchMore={fetchMore}
                            loading={loading}
                            data={data}
                        />

                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.requests.hotel_data,
    pagination: state.requests.pagination,
    loading: state.requests.loading,
    error: state.requests.error,
})

const mapDispatchToProps = dispatch => ({
    HotelRequestAction: bindActionCreators({HotelRequestAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)