import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import ContactMailOutlined from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/auth/UserAction";
import Constants from "Utils/constatns.js";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const useStyles = makeStyles(styles);

function UserInfo(props) {

    const [setctedType, setSelectedType] = React.useState();

    const {dispatch, client} = props;

    const {user_details} = props;

    useEffect(() => {


    }, props.loading);


    const updateUserBasicData = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        dispatch(UserAction.addNewuser(client, fd)).then(res => {

            if (res.message == 1)
                window.location.href = "/admin/user/" + res.User.id

        });

    };

    const classes = useStyles();

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PersonOutline/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Personal Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserBasicData(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        First Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="first_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "first_name",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Last Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="last_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "last_name",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Email Address
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "email",
                                            name: "email",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Password
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "password",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Commission Percent %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="commission"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            step: "any",
                                            name: "percent",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer md={12}>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Type</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <div style={{paddingTop: 29}}>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                value={setctedType}
                                                onChange={e => setSelectedType(e.target.value)}
                                                inputProps={{
                                                    name: 'type',
                                                }}
                                            >
                                                <MenuItem value="user">User</MenuItem>
                                                <MenuItem value="operator">Operator</MenuItem>
                                                <MenuItem value="hotel">Hotel</MenuItem>
                                                <MenuItem value="reseller">Reseller</MenuItem>
                                                {
                                                    user_details.type == "admin" &&
                                                    <MenuItem value="admin">Admin</MenuItem>

                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                </GridItem>

                            </GridContainer>
                            <Button type={"submit"} color="rose">Submit</Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.user.user,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo)