import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import ContactMailOutlined from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import helper from 'Utils/helper.js';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/auth/UserAction";
import Constants from "Utils/constatns.js";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles({
    ...stylesAlert,
    ...styles
});

function UserInfo(props) {
    const [permissions, setPermission] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [init, setinit] = React.useState(false);
    const handleToggle = value => {

        let found = false;
        let per = permissions.filter(e => {
            if (e == value)
                found = true
            return e != value
        })
        if (!found)
            per.push(value)
        setPermission(per)


    };
    const updatePermissions = () => {
        let id = props.match.params.user_id;

        setLoading(true);

        dispatch(UserAction.UpdateUserPermission(client, id, permissions)).then(res => {
            setLoading(false);

            if (res.message == 1) {
                successUpload("Permission Has Been Updated.", "Updated!")

            }

        });


    };
    const [alerts, setAlert] = React.useState(null);

    const {dispatch, client} = props;

    const {data,user_details} = props;
    const successUpload = (desc, title) => {

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {desc}
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };
    useEffect(() => {
        let id = props.match.params.user_id;

        dispatch(UserAction.GetUser(client, id))

    }, props.loading);
    useEffect(() => {
        if (!props.loading && !init)
            setPermissions()
    }, null);

    const setPermissions = () => {
        setinit(true)
        let per = data.roles.map(e => {
            return e.role

        })
        setPermission(per)
    };
    const hasPermission = (permission) => {

        let has = data.roles.filter(e => {
            if (e.role == permission) {
                return e
            }
        });
        return has.length > 0

    };
    const updateUserBasicData = (e) => {
        e.preventDefault();

        let id = props.match.params.user_id;

        let fd = new FormData(e.target);
        setLoading(true);
        dispatch(UserAction.updateUserBasicData(client, id, fd)).then(res => {
            setLoading(false);

            if (res.message == 1) {
                successUpload("Personal Information Has Been Updated.", "Updated!")

            }

        });

    };
    const updateUserContactInfo = (e) => {
        e.preventDefault();

        let id = props.match.params.user_id;

        let fd = new FormData(e.target);
        setLoading(true);

        dispatch(UserAction.updateUserContactInfo(client, id, fd)).then(res => {
            setLoading(false);

            if (res.message == 1) {
                successUpload("Contact Information Has Been Updated.", "Updated!")

            }

        });

    };
    const updateUserBankInfo = (e) => {
        e.preventDefault();

        let id = props.match.params.user_id;
        setLoading(true);

        let fd = new FormData(e.target);
        dispatch(UserAction.updateUserBankInfo(client, id, fd)).then(res => {
            setLoading(false);

            if (res.message == 1) {
                successUpload("Bank Information Has Been Updated.", "Updated!")

            }
        });

    };

    const classes = useStyles();
    if (props.loading)
        return "Loading..."

    return (
        <GridContainer>
            {alerts}

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PersonOutline/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Personal Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserBasicData(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        First Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="first_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "first_name",
                                            defaultValue: data && data.first_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Last Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="last_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "last_name",
                                            defaultValue: data && data.last_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Identity Code | Passport No.
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "identity",
                                            defaultValue: data && data.identity
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Email Address
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "email",
                                            name: "email",
                                            defaultValue: data && data.email
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Commission Percent %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="commission"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            step: "any",
                                            name: "percent",
                                            defaultValue: data && data.percent
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            {
                                loading ? <Button color="warning">Please Wait...</Button> :
                                    <Button type={"submit"} color="rose">Submit</Button>
                            }


                        </form>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <ContactMailOutlined/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Contact Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserContactInfo(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Mobile</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="mobile"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "mobile",
                                            defaultValue: data && data.contact_info && data.contact_info.mobile
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Phone</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "phone",
                                            defaultValue: data && data.contact_info && data.contact_info.phone
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Country</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="country"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "country",
                                            defaultValue: data && data.contact_info && data.contact_info.country
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>State/Region</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="state"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "state",
                                            defaultValue: data && data.contact_info && data.contact_info.state
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>City</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="city"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "city",
                                            defaultValue: data && data.contact_info && data.contact_info.city
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Address</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "address",
                                            defaultValue: data && data.contact_info && data.contact_info.address
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Postal Code</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="postal_code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "postal_code",
                                            defaultValue: data && data.contact_info && data.contact_info.postal_code
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            {
                                loading ? <Button color="warning">Please Wait...</Button> :
                                    <Button type={"submit"} color="rose">Submit</Button>
                            }                        </form>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <AccountBalanceOutlined/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Bank Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => updateUserBankInfo(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>IBAN</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="IBAN"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "IBAN",
                                            defaultValue: data && data.bank_info && data.bank_info.IBAN
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Currency</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="Currency"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "Currency",
                                            defaultValue: data && data.bank_info && data.bank_info.Currency
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Swift Code</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="swift_code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "swift_code",
                                            defaultValue: data && data.bank_info && data.bank_info.swift_code
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Holder Name</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="holder_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "holder_name",
                                            defaultValue: data && data.bank_info && data.bank_info.holder_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Bank Card number</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="bank_card_number"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "bank_card_number",
                                            defaultValue: data && data.bank_info && data.bank_info.bank_card_number
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Bank Info</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="bank_info"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "bank_info",
                                            defaultValue: data && data.bank_info && data.bank_info.bank_info
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            {
                                loading ? <Button color="warning">Please Wait...</Button> :
                                    <Button type={"submit"} color="rose">Submit</Button>
                            }                        </form>
                    </CardBody>
                </Card>
            </GridItem>
            {
                (helper.isAdmin(user_details.type) ||
                    helper.hasPermission("change_permission", user_details.roles)) && <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <LockOpenOutlined/>
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>User Permissions</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    {
                                        Constants.Permissions.map((e, i) => {


                                            return <GridItem key={i} xs={12} sm={12} md={3}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={hasPermission(e.value)}
                                                                tabIndex={-1}
                                                                onClick={() => handleToggle(e.value)}
                                                                checkedIcon={
                                                                    <Check className={classes.checkedIcon}/>
                                                                }
                                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label={e.key}
                                                    />


                                                </div>
                                            </GridItem>
                                        })
                                    }

                                </GridContainer>
                                {
                                    loading ? <Button color="warning">Please Wait...</Button> :
                                        <Button onClick={e => updatePermissions()} color="rose">Submit</Button>
                                }

                            </form>
                        </CardBody>
                    </Card>
                </GridItem>

            }

        </GridContainer>
    );
}

const mapStateToProps = state => ({
     data: state.user.user,
    loading: state.user.loading,
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo)