import gql from 'graphql-tag';

import {
    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILED,
    GET_USER_REQUEST,
    GET_USER_FAILED,
    GET_USER_SUCCESS,
} from "../../types/User";

const GetUserList = (client, pagination = {per_page: 15, page: 1}) => {

    return dispatch => {
        dispatch(request())
        return client.query({
            query: gql`
                    query GetUserList {
                      GetUserList(pagination:{perPage:${pagination.per_page},page:${pagination.page}}) {
                        Items {
                          id
                          first_name
                          last_name
                          email
                          type
                        }
                        pagination {
                          total
                          current_page
                          last_page
                          per_page
                          from
                          to
                        }
                      }
                    }
                 `
        }).then(res => {
            dispatch(success(res.data))
        }).catch(error => {
            dispatch(failure(error))
        })

        function request() {
            return {type: GET_USER_LIST_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_LIST_SUCCESS,
                payload: result.GetUserList.Items,
                pagination: result.GetUserList.pagination
            }
        }

        function failure(error) {
            return {type: GET_USER_LIST_FAILED, error: {error}}
        }
    }
};
const GetUser = (client, id) => {

    return dispatch => {
        dispatch(request())
        return client.query({
            query: gql`
                query   {
                  GetUser(user_id:${id}){
                    id
                    first_name
                    last_name
                    email
                    type
                    percent
                    identity
                    contact_info{
                      phone
                      mobile
                      country
                      state
                      city
                      address
                      postal_code
                    }
                    bank_info{
                      IBAN
                      bank_card_number
                      Currency
                      swift_code
                      holder_name
                      bank_info
                    }
                    roles{
                      id
                      role
                    }
                  }
                }
                 `
        }).then(res => {
            dispatch(success(res.data))
        }).catch(error => {
            dispatch(failure(error))
        })

        function request() {
            return {type: GET_USER_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_SUCCESS,
                payload: result.GetUser,
            }
        }

        function failure(error) {
            return {type: GET_USER_FAILED, error: {error}}
        }
    }
};
const UpdateUserPermission = (client, user_id, roles = []) => {


    return dispatch => {
        return client.mutate({
            mutation: gql`
                    mutation  {
                       UpdateUserPermissions(user_id:${user_id},roles:${JSON.stringify(roles)}){
                        User{
                          id
                          first_name
                        }
                        message
                      }
                    }
                 `
        }).then(res => {
            return res.data.UpdateUserPermissions


        }).catch(error => {


            console.log(error)
        })

    }
};
const updateUserBasicData = (client, user_id, data) => {

    return dispatch => {
        return client.mutate({
            mutation: gql`
                    mutation updateUser{
                      UpdatePanelUser(user_id:${user_id},first_name:"${data.get("first_name")}",
                      last_name:"${data.get("last_name")}",email:"${data.get("email")}",identity:"${data.get("identity")}",percent:${data.get("percent")}){
                        User{
                          id
                        }
                        message
                      }
                    }
                 `
        }).then(res => {
            return res.data.UpdatePanelUser


        }).catch(error => {


            console.log(error)
        })

    }
};
const updateUserContactInfo = (client, user_id, data) => {

    return dispatch => {
        return client.mutate({
            mutation: gql`
                mutation contact {
                  UpdatePanelUserContact(
                    user_id:${user_id},
                    phone: "${data.get("phone")}"
                    mobile: "${data.get("mobile")}"
                    country: "${data.get("country")}"
                    state: "${data.get("state")}"
                    city: "${data.get("city")}"
                    address: "${data.get("address")}"
                    postal_code: "${data.get("postal_code")}"
                  ) {
                    User {
                      first_name
                      last_name
                      email
                      contact_info {
                        phone
                        mobile
                        country
                        state
                        city
                        city
                        address
                        postal_code
                      }
                    }
                    message
                  }
                }
                 `
        }).then(res => {
            return res.data.UpdatePanelUserContact


        }).catch(error => {


            console.log(error)
        })

    }
};

const updateUserBankInfo = (client, user_id, data) => {

    return dispatch => {
        return client.mutate({
            mutation: gql`
                mutation bank {
                  UpdatePanelUserBankInfo(
                    user_id:${user_id},
                    bank_card_number:"${data.get("bank_card_number")}"
                    IBAN: "${data.get("IBAN")}"
                    Currency: "${data.get("Currency")}"
                    swift_code: "${data.get("swift_code")}"
                    holder_name: "${data.get("holder_name")}"
                    bank_info: "${data.get("bank_info")}"
                  ) {
                    User {
                      first_name
                      last_name
                      email
                      bank_info {
                        IBAN
                        Currency
                        swift_code
                        holder_name
                        bank_info
                      }
                      contact_info {
                        phone
                        mobile
                        country
                        state
                        city
                        address
                        postal_code
                      }
                    }
                    message
                  }
                }

                 `
        }).then(res => {
            return res.data.UpdatePanelUserBankInfo


        }).catch(error => {


            console.log(error)
        })

    }
};

const addNewuser = (client, data) => {

    return dispatch => {
        return client.mutate({
            mutation: gql`
                    mutation {
                      AddNewUser(
                        first_name: "${data.get("first_name")}"
                        last_name: "${data.get("last_name")}"
                        identity: "${data.get("identity")}"
                        email: "${data.get("email")}"
                        percent: "${data.get("percent")}"
                        password: "${data.get("password")}"
                        type: "${data.get("type")}"
                      ) {
                        User {
                          id
                        }
                        message
                      }
                    }
                 `
        }).then(res => {
            return res.data.AddNewUser


        }).catch(error => {


            console.log(error)
        })

    }
};
export default {
    GetUserList,
    GetUser,
    UpdateUserPermission,
    updateUserBasicData,
    updateUserContactInfo,
    updateUserBankInfo,
    addNewuser,
}