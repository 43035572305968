import gql from 'graphql-tag';

import {
    GET_HOTELS_REQUESTS_FAILED,
    GET_HOTELS_REQUESTS_SUCCESS,
    GET_HOTELS_REQUESTS_REQUEST,
    GET_HOTEL_REQUEST_REQUEST,
    GET_HOTEL_REQUEST_SUCCESS,
    GET_HOTEL_REQUEST_FAILED,
    GET_HOTEL_RESPONE_REQUEST,
    GET_HOTEL_RESPONE_SUCCESS,
    GET_HOTEL_RESPONE_FAILED
} from "../../types/requests";

const GetHotelRequests = (client, pagination = {per_page: 15, page: 1}, status) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
      query{
                  GetHotelRequests(status: "${status}",pagination:{perPage:${pagination.per_page},page:${pagination.page}}) {
    
    items{
     id
      token
      check_in
      check_out
      count
      cost
      final_price
      room_id
      first_name
      last_name
      mobile
      email
      nationality
      passport_number
      special_request
      updated_at
      created_at
      address
      country
      postalCode
      city
      persian_check_out
      persian_check_in
      persian_created_at
      transaction
      status
      book_response{
        id
      book_request_id
      price
      is_day
      expire_time
      description
      payments_status
      cancellation
      }
      room{
        id
        accommodation{
          name
        }
        name
        description
        type
        capacity
        price_full_board
        price_discount
      }
    }
     pagination{
                    total
                    current_page
                    last_page
                    per_page
                    from
                    to
                  }
  }
  }
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTELS_REQUESTS_FAILED}
        }

        function success(result) {
            return {
                type: GET_HOTELS_REQUESTS_SUCCESS,
                payload: result.GetHotelRequests.items,
                pagination: result.GetHotelRequests.pagination
            }
        }

        function failure(error) {
            return {type: GET_HOTELS_REQUESTS_REQUEST, error: {error}}
        }
    }
}
const GetHotelRequest = (client, token) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
       query{
          TrackingHotelRequest(token:"${token}") {
            id
            room {
              name
              capacity
              accommodation {
                name
                cover
              }
            }
            status
            promo_code{
                code
                percent
                price
                max_price            
            }
            check_in
            check_out
            persian_check_out
            persian_check_in
            persian_created_at
            created_at
            token
            count
            cost
            final_price
            first_name
            last_name
            mobile
            email
            nationality
            passport_number
            country
            city
            address
            postalCode
            transaction
            book_response{
              id
              hotel_request_id
              description
              expire_time
              cancellation
              price
              is_day
            }
          }
        }
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTEL_REQUEST_REQUEST}
        }

        function success(result) {
            return {
                type: GET_HOTEL_REQUEST_SUCCESS,
                payload: result.TrackingHotelRequest,
            }
        }

        function failure(error) {
            return {type: GET_HOTEL_REQUEST_FAILED, error: {error}}
        }
    }
}
const UpdateRequestResponse = (client, data) => {


    let is_day = data.get('is_day');
    let description = data.get('description');
    let expire_time = data.get('expire_time');
    let price = data.get('price');
    let hotel_request_id = data.get('hotel_request_id');
    let id = data.get('id');
    let cancellation = data.get('cancellation');

    return dispatch => {

        dispatch(request())
        return client.mutate({
            mutation: gql`
       mutation {
   UpdateHotelResponse(id: ${id}, hotel_request_id: ${hotel_request_id}, 
    price: ${price}, is_day: ${is_day}, expire_time: ${expire_time}, description: "${description}", cancellation: "${cancellation}") {
       id
            room {
              name
              capacity
              accommodation {
                name
                cover
              }
            }
            promo_code{
                code
                percent
                price
                max_price
            }
            status
            check_in
            check_out
            persian_check_out
            persian_check_in
            persian_created_at
            created_at
            token
            count
            cost
            final_price
            first_name
            last_name
            mobile
            email
            nationality
            passport_number
            country
            city
            address
            postalCode
            transaction
            book_response{
              id
              hotel_request_id
              description
              expire_time
              cancellation
              price
              is_day
            }
    }
}
          `
        }).then(result => {
            console.log(result)
            return dispatch(success(result.data))
        }).catch(error => {
            console.log(error)
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_HOTEL_RESPONE_REQUEST}
        }

        function success(result) {
            return {
                type: GET_HOTEL_RESPONE_SUCCESS,
                payload: result.UpdateHotelResponse,
            }
        }

        function failure(error) {
            return {type: GET_HOTEL_RESPONE_FAILED, error: {error}}
        }
    }
}

const UpdateRequestStatus = (client, id, status) => {

    return client.mutate({
        mutation: gql`
          mutation{
    UpdateHotelResponseStatus(id:${id},status: "${status}") {
      id
      room {
        name
        capacity
        accommodation {
          name
          cover
        }
      }
      status
      check_in
      check_out
      count
      cost
      final_price
      first_name
      last_name
      mobile
      email
      nationality
      passport_number
      country
      city
      address
      postalCode
      transaction
      book_response{
        id
        hotel_request_id
        description
        expire_time
        cancellation
        price
        is_day
      }
    }
  }`
    }).then(res => {
        console.log(res)
        return res.data.UpdateHotelResponseStatus
    }).catch(err => {
        console.log(err)

        return err;
    })
}


export default {
    GetHotelRequests,
    GetHotelRequest,
    UpdateRequestStatus,
    UpdateRequestResponse,

}