import React, {Fragment, useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Book from "@material-ui/icons/Book";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Photo from "@material-ui/icons/Photo";
import Facilitites from "@material-ui/icons/RoomService";
import Room from "@material-ui/icons/MeetingRoom";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import Helper from "Utils/helper.js";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import "./table-style.css";
import {
    tooltip,
} from "assets/jss/material-dashboard-pro-react.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import extend from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";

const styles = {
    extend,
    tooltip,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },

};

const useStyles = makeStyles(styles);

function HotelList(props) {

    const {dispatch, client} = props;
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const [filters, setFilters] = React.useState({
        from: date.toISOString().split('T')[0], to: new Date().toISOString().split('T')[0]
    });

    const [ranges, setRanges] = React.useState({
        from: date.toISOString().split('T')[0], to: new Date().toISOString().split('T')[0]
    });
    useEffect(() => {
        fetchMore();
    }, props.loading);

    const fetchMore = () => {
        let id = props.match.params.hotel_id;
        client.cache.reset()
        dispatch(HotelAction.getHotelRooms(client, id));
    }
    const apply = () => {

        setRanges({
            from: filters.from,
            to: filters.to,
        })

    }
    const reserve = (date, room_id, room_name) => {

        addNewEventAlert(date, room_id, room_name)
    }
    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    };
    const submit = (e, room_id, check_in) => {
        e.preventDefault();
        const {client} = props;
        let fd = new FormData(e.target)
        fd.append("room_id", room_id)
        fd.append("check_in", check_in)
        dispatch(HotelAction.updateHotelRoomRequest(client, fd)).then(res => {
            fetchMore()
            hideAlert()
        })

    }
    const addNewEventAlert = (date, room_id, room_name) => {


        setAlert(
            <SweetAlert
                style={{top: "40%", width: "600", minHeight: 600}}
                title={"Reservation Data for " + room_name}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                showConfirm={false}
                cancelBtnCssClass={classes.button + " " + classes.danger}
            >Please enter the reservation data in the fields below<br/><br/><br/>
                <form onSubmit={e => submit(e, room_id, date)}>
                    <GridContainer xs={12}>
                        <GridContainer xs={12} sm={12} md={6}>
                            <GridItem xs={12} sm={6}>
                                <FormLabel className={classes.labelHorizontal}>
                                    Check-in:
                                </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <FormLabel className={classes.labelHorizontalBlack}>
                                    {date}
                                </FormLabel>
                            </GridItem>
                        </GridContainer>
                        <br/><br/><br/>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat={"YYYY-MM-DD"}
                                    inputProps={{
                                        placeholder: "Check-out", name: "check_out", required: "true"
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        <br/><br/><br/>

                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText={"Count"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "number",
                                    name: "count",
                                    minimum: 0,
                                    required: "required",
                                    defaultValue: 1,
                                }}
                            />

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button onClick={hideAlert} color="danger">Cancel</Button>
                            <Button type={"submit"} color="success">Save</Button>

                        </GridItem>
                    </GridContainer>

                </form>
            </SweetAlert>
        );
    };
    const onChangeDate = (type, e) => {


        setFilters({
            ...filters, [type]: e.format("YYYY-MM-DD")
        })

    }

    const classes = useStyles();
    const {data, loading} = props;

    if (loading)
        return "Loading...";

    const getDates = () => {

        let first_date = ranges.from, last_date = ranges.to

        const days = Helper.dateDiffInDays(first_date, last_date);

        let dates = [first_date];
        let tableHeader = [
            <td>
                Room Name | Date
            </td>,
            <td>
                {first_date}
            </td>];
        for (let i = 0; i < days; i++) {
            const date = moment(first_date)
            let newDate = date.add(i + 1, "days").format("YYYY-MM-DD");
            dates.push(newDate)
            tableHeader.push(
                <td>
                    {`${newDate}`}
                </td>
            )
        }
        let tableBody = [];


        for (let i = 0; i < data.length; i++) {
            let tableBodyColumns = [
                <td>
                    {data[i].name}
                </td>
            ];

            for (let j = 0; j < dates.length; j++) {

                let is_booked = "";
                for (let k = 0; k < data[i].requests.length; k++) {

                    const diff = Helper.dateDiffInDays(data[i].requests[k].check_in, data[i].requests[k].check_out);
                    const cpmpair = Helper.dateDiffInDays(dates[0], data[i].requests[k].check_out);

                    let size = (diff + 1) * 100;
                    if ((diff + 1) > (dates.length - j))
                        size = (dates.length - j) * 100

                    if (data[i].requests[k].check_in == dates[j]) {
                        is_booked = data[i].requests[k].booked_by_hotel ?
                            <div style={{width: size}}
                                 className={"booked-hotel"}>
                                Booked by Hotel
                            </div> :
                            <a target={"_blank"} href={"/admin/hotel-request-details/" + data[i].requests[k].token}>
                                <div style={{width: size}} className={"booked"}>
                                    Booked by: {data[i].requests[k].first_name}
                                </div>
                            </a>
                    }

                    if (diff > cpmpair) {
                        data[i].requests[k].check_out == dates[j] && (is_booked = data[i].requests[k].booked_by_hotel ?
                            <div style={{width: size}}
                                 className={"booked-hotel"}>
                                Booked by Hotel
                            </div> :
                            <a target={"_blank"} href={"/admin/hotel-request-details/" + data[i].requests[k].token}>
                                <div style={{width: size}} className={"booked"}>
                                    Booked by: {data[i].requests[k].first_name}
                                </div>
                            </a>)
                    }
                }


                tableBodyColumns.push(<td onClick={e => reserve(dates[j], data[i].id, data[i].name)}>
                    {
                        is_booked
                    }
                </td>)

            }
            tableBody.push(<tr>
                {tableBodyColumns}
            </tr>)
        }


        return {tableHeader, tableBody};

    }


    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Book/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Hotels</h4>
                        <GridContainer justify="flex-end">
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl fullWidth>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={"YYYY-MM-DD"}
                                        onChange={e => onChangeDate("from", e)}
                                        inputProps={{
                                            placeholder: "From",
                                        }}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl fullWidth>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={e => onChangeDate("to", e)}
                                        dateFormat={"YYYY-MM-DD"}
                                        inputProps={{
                                            placeholder: "To",
                                        }}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <Button onClick={e => apply()} color="primary">Apply Filters</Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>

                        <div className={"request"}>

                            <table>
                                <thead>
                                <tr>
                                    {getDates().tableHeader}
                                </tr>
                                </thead>
                                <tbody>
                                {getDates().tableBody}

                                </tbody>
                            </table>

                        </div>


                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.data,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HotelList)