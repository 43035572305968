export const prefix = "user/";
export const GET_USER_LIST_REQUEST = prefix + 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = prefix + 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILED = prefix + 'GET_USER_LIST_FAILED';


export const GET_USER_REQUEST = prefix + 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = prefix + 'GET_USER_SUCCESS';
export const GET_USER_FAILED = prefix + 'GET_USER_FAILED';

export const GET_USER_DETAILS_REQUEST = prefix + 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = prefix + 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED  = prefix + 'GET_USER_DETAILS_FAILED';


export const GET_USER_WALLET_REQUEST = prefix + 'GET_USER_WALLET_REQUEST';
export const GET_USER_WALLET_SUCCESS = prefix + 'GET_USER_WALLET_SUCCESS';
export const GET_USER_WALLET_FAILED = prefix + 'GET_USER_WALLET_FAILED';

export const GET_USER_LOGS_REQUEST = prefix + 'GET_USER_LOGS_REQUEST';
export const GET_USER_LOGS_SUCCESS = prefix + 'GET_USER_LOGS_SUCCESS';
export const GET_USER_LOGS_FAILED  = prefix +  'GET_USER_LOGS_FAILED';
