const Permissions = [
    {
        key: "Change User Permissions",
        value: "change_permission",
    },

    {
        key: "Access to Users",
        value: "user_permission",
    },

    {
        key: "Access to Hotel List",
        value: "hotel_permission",
    },

    {
        key: "Change Room Price and Availability",
        value: "room_availability",
    },

    {
        key: "Access Hotel Requests",
        value: "hotel_requests",
    },

    {
        key: "Access Requests",
        value: "requests",
    },

    {
        key: "Access Transaction list",
        value: "transaction",
    },

    {
        key: "Clear Payouts",
        value: "clear_payout",
    },

    {
        key: "Has Wallet",
        value: "wallet",
    },
    {
        key: "Access User Profile",
        value: "profile",
    },
    {
        key: "Change Hotel Special Options",
        value: "hotel_special_items",
    },
    {
        key: "Add New Hotel",
        value: "add_hotel_permission",
    },
    {
        key: "Add User To Hotel",
        value: "add_user_to_hotel_permission",
    },
    {
        key: "Promo Code List",
        value: "get_promo_code",
    },
    {
        key: "Add Promo Codes",
        value: "update_promo_code",
    },
    {
        key: "Change Promo Code ",
        value: "edit_promo_code_commissions",
    },
    {
        key: "Can Change Promo Code Commission Percent ",
        value: "edit_commissions_percent",
    },
    {
        key: "Generate Affiliate Links",
        value: "generate_affiliate_links",
    },
];

export default {
    Permissions
}