import {
    GET_PROMO_CODE_LIST_REQUEST,
    GET_PROMO_CODE_LIST_SUCCESS,
    GET_PROMO_CODE_LIST_FAILED,
    GET_PROMO_CODE_REQUEST,
    GET_PROMO_CODE_SUCCESS,
    GET_PROMO_CODE_FAILED
} from "../types/PromoCode";

const initialState = {
    data: [],
    pagination: {
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 15,
        from: 0,
        to: 0
    },
    loading: true,
    error: {},
    promo_code: {},

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROMO_CODE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_PROMO_CODE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                pagination: action.pagination
            }
        }
        case GET_PROMO_CODE_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case GET_PROMO_CODE_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_PROMO_CODE_SUCCESS: {
            return {
                ...state,
                loading: false,
                promo_code: action.payload,
             }
        }
        case GET_PROMO_CODE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }

        default:
            return state

    }
}