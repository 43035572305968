export const prefix = "hotel/";
export const GET_HOTELS_REQUEST = prefix + 'GET_HOTELS_REQUEST';
export const GET_HOTELS_SUCCESS = prefix + 'GET_HOTELS_SUCCESS';
export const GET_HOTELS_FAILED = prefix + 'GET_HOTELS_FAILED';


export const GET_HOTEL_BASIC_DATA_REQUEST = prefix + 'GET_HOTEL_BASIC_DATA_REQUEST';
export const GET_HOTEL_BASIC_DATA_SUCCESS = prefix + 'GET_HOTEL_BASIC_DATA_SUCCESS';
export const GET_HOTEL_BASIC_DATA_FAILED  = prefix + 'GET_HOTEL_BASIC_DATA_FAILED';

export const GET_FACILITIES_REQUEST = prefix + 'GET_FACILITIES_REQUEST';
export const GET_FACILITIES_SUCCESS = prefix + 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILED  = prefix + 'GET_FACILITIES_FAILED';


