import React, {useEffect} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hotel from "@material-ui/icons/Hotel";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTableCustom from "components/CustomTable";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {dataTable} from "variables/general.js";
import {Calendar as BigCalendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import HotelAction from "actions/hotel/HotelAction";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon/Icon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {apiUrl} from "../../config";
import Euro from "@material-ui/icons/EuroSymbol";
import NavPills from "components/NavPills/NavPills.js";
import {events as calendarEvents} from "variables/general.js";
import helper from "Utils/helper.js";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const styles = {
    ...stylesAlert,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const localizer = momentLocalizer(moment);

function Index(props) {
    const [events, setEvents] = React.useState([]);
    const [alert, setAlert] = React.useState(null);
    const [roomData, setRoomData] = React.useState([]);
    const [active, setActive] = React.useState(0);
    const selectedEvent = (event, id) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Are you sure wants to delete?"
                onConfirm={() => Delete(event.id, id)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >If you delete this item, it will effects on pricing!</SweetAlert>
        );
    };


    const addNewEventAlert = (slotInfo, id) => {


        setAlert(
            <SweetAlert
                style={{display: "block", marginTop: "-100px"}}
                title="Enter Perice and After Discount Price"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                showConfirm={false}
                cancelBtnCssClass={classes.button + " " + classes.danger}
            >
                Enter price and after discount for date
                range <b>{new moment(slotInfo.start).format("YYYY-MM-DD")}</b> to <b>{new moment(slotInfo.end).format("YYYY-MM-DD")}</b><br/>

                Discount can be 0.<br/>
                <form
                    onSubmit={e => updatePrice(e, new moment(slotInfo.start).format("YYYY-MM-DD"), new moment(slotInfo.end).format("YYYY-MM-DD"), id)}>
                    <GridContainer xs={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                id="price"
                                labelText={"Price"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    required: "required",
                                    name: "price",
                                }}
                            />

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                id="discount"
                                labelText={"After Discount Price"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "text",
                                    name: "discount",
                                    required: "required",
                                    defaultValue: 0,
                                }}
                            />

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button onClick={hideAlert} color="danger">Cancel</Button>
                            <Button type={"submit"} color="success">Save</Button>

                        </GridItem>
                    </GridContainer>

                </form>
            </SweetAlert>
        );
    };
    const addNewEvent = (e, slotInfo) => {
        var newEvents = events;
        newEvents.push({
            title: e,
            start: slotInfo.start,
            end: slotInfo.end
        });
        setAlert(null);
        setEvents(newEvents);
    };
    const hideAlert = () => {
        setAlert(null);
    };

    const updatePrice = (e, start, end, id) => {

        e.preventDefault()

        const {client} = props;
        let fd = new FormData(e.target)
        fd.append("start_date", start)
        fd.append("end_date", end)
        fd.append("room_id", id)
        dispatch(HotelAction.updateRoomPrice(client, fd)).then(res => {
            successDelete(id)
        })


    };
    const eventColors = event => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };
    const {dispatch, client} = props;
    const classes = useStyles();
    useEffect(() => {
        fetchData();

    }, props.loading);

    const fetchData = () => {
        let id = props.match.params.hotel_id;
        client.cache.reset()
        dispatch(HotelAction.getHotel(client, id));

    }
    const {data} = props;

    const renderTabs = () => {

        return (data.rooms || []).map((e, i) => {
            return {
                tabButton: e.name + " | " + e.terms_data.title,
                tabContent: (
                    <BigCalendar
                        selectable
                        localizer={localizer}
                        events={getDates(e.prices)}
                        defaultView="month"
                        views={['month']}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectEvent={event => selectedEvent(event, e.id)}
                        onSelectSlot={slotInfo => addNewEventAlert(slotInfo, e.id)}
                        eventPropGetter={eventColors}
                    />
                )
            }
        })

    }
    const successDelete = (id) => {
        fetchData()

        data.rooms.map((e, i) => {

            if (e.id == id) {
                console.log(e)
                setActive(i)

            }
        })

        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Saved!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >Price Has been added successfully!</SweetAlert>
        );
    };
    const Delete = (id, room_id) => {

        dispatch(HotelAction.RemovePrice(client, id)).then(res => {
            fetchData()

        });

        data.rooms.map((e, i) => {

            if (e.id == room_id) {
                setActive(i)
            }
        })
        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Saved!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >Price Has been deleted successfully!</SweetAlert>
        );
    };
    const getDates = (prices) => {

        return (prices || []).map((e, i) => {

            return {
                title: "Price: " + helper.formatter.format(e.price) + " | after discount price: " + helper.formatter.format(e.discount),
                allDay: false,
                id: e.id,
                start: new Date(e.start_date),
                end: new Date(e.end_date),
                color: "success"
            }
        })


    }

    if (props.loading)

        return "Loading ..."
    return (

        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="danger" icon>
                        <CardIcon color="danger">
                            <Euro/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Pricing Rooms</h4>
                    </CardHeader>
                    <CardBody>
                        <NavPills
                            horizontal={{
                                tabsGrid: {xs: 12, sm: 12, md: 3},
                                contentGrid: {xs: 12, sm: 12, md: 9}
                            }}
                            active={active}
                            color="danger"
                            tabs={renderTabs()}
                        />

                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
    );
}

const mapStateToProps = state => ({
    data: state.hotel.hotel,
    loading: state.hotel.loading,
    error: state.hotel.error,
})

const mapDispatchToProps = dispatch => ({
    HotelAction: bindActionCreators({HotelAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)