import gql from 'graphql-tag';

import {

    GET_USER_WALLET_REQUEST,
    GET_USER_WALLET_SUCCESS,
    GET_USER_WALLET_FAILED,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_USER_LOGS_REQUEST,
    GET_USER_LOGS_SUCCESS,
    GET_USER_LOGS_FAILED

} from "../../types/User";

const GetUserDetails = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query   {
                  GetUserDetails{
                    id
                    first_name
                    last_name
                    merchant_id
                    email
                    type
                    identity
                    identity_photo
                    user_profile
                    percent
                    contact_info{
                      phone
                      mobile
                      country
                      state
                      city
                      address
                      postal_code
                    }
                    bank_info{
                      IBAN
                      bank_card_number
                      Currency
                      swift_code
                      holder_name
                      bank_info
                    }
                    roles{
                      id
                      role
                    }
                  }
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_USER_DETAILS_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_DETAILS_SUCCESS,
                payload: result.GetUserDetails,
            }
        }

        function failure(error) {
            return {type: GET_USER_DETAILS_FAILED, error: {error}}
        }
    }
}
const GetWallet = (client) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query{
                 GetWallet{
                     euro
                     rials
                     percent
                     pending_payout
                     payout
                 }
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_USER_WALLET_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_WALLET_SUCCESS,
                payload: result.GetWallet,
            }
        }

        function failure(error) {
            return {type: GET_USER_WALLET_FAILED, error: {error}}
        }
    }
}
const GetLogs = (client, pagination = {per_page: 15, page: 1}) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            variables: {
                per_page: pagination.per_page,
                page: pagination.page,
            },
            query: gql`
                query getLogs($per_page: Int,$page:Int){
                  GetPanelUserActivity(pagination:{perPage:$per_page,page:$page}){
                    Items{
                      user{
                        first_name
                        last_name
                        email
                      }
                      activity
                      persian_created_at
                      created_at
                    }
                    pagination{
                      total
                      current_page
                      last_page
                      per_page
                      from
                      to
                    }
                  }
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_USER_LOGS_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_LOGS_SUCCESS,
                payload: result.GetPanelUserActivity.Items,
                pagination: result.GetPanelUserActivity.pagination

            }
        }

        function failure(error) {
            return {type: GET_USER_LOGS_FAILED, error: {error}}
        }
    }
}
const GetUserWallet = (client, user) => {
    return dispatch => {

        dispatch(request())
        return client.query({
            query: gql`
                query{
                 GetUserWallet(panel_user_id:${user}){
                     euro
                     rials
                     pending_payout
                     payout
                  user{
                    id
                    first_name
                    last_name
                    email      
                    company 
                  }
                 }
                }
          `
        }).then(result => {
            return dispatch(success(result.data))
        }).catch(error => {
            dispatch(failure(error))
        });

        function request() {
            return {type: GET_USER_WALLET_REQUEST}
        }

        function success(result) {
            return {
                type: GET_USER_WALLET_SUCCESS,
                payload: result.GetUserWallet,
            }
        }

        function failure(error) {
            return {type: GET_USER_WALLET_FAILED, error: {error}}
        }
    }
}

const RequestExchange = (client, amount = 0) => {
    return dispatch => {

        return client.mutate({
            mutation: gql`
                mutation{
                  RequestExchange(amount:${amount}) 
                }
          `
        }).then(result => {
            return result.data.RequestExchange
        }).catch(error => {
            console.log(error)
        });


    }
}

const updateContactInfo = (client, fd) => {

    let phone = fd.get("phone")
    let mobile = fd.get("mobile")
    let country = fd.get("country")
    let state = fd.get("state")
    let city = fd.get("city")
    let address = fd.get("address")
    let postal_code = fd.get("postal_code")

    return dispatch => {

        return client.mutate({
            mutation: gql`
                mutation {
                  UpdatePanelUserContact(
                    phone: "${phone}"
                    mobile: "${mobile}"
                    country: "${country}"
                    state: "${state}"
                    city: "${city}"
                    address: "${address}"
                    postal_code: "${postal_code}"
                  ) {
                    User {
                      id
                      first_name
                      last_name
                      
                    }
                    message
                  }
                }               
          `
        }).then(result => {
            return result.data.UpdatePanelUserContact
        }).catch(error => {
            console.log(error)
        });


    }
}


const RequestPayout = (client, amount = 0) => {
    return dispatch => {

        return client.mutate({
            mutation: gql`
                mutation{
                  RequestPayout(amount:${amount}) 
                }
          `
        }).then(result => {
            console.log(result)
            return result.data.RequestPayout
        }).catch(error => {
            console.log(error)
        });


    }
}
const ClearPayout = (client, amount = 0, description = "", id) => {
    return dispatch => {

        return client.mutate({
            mutation: gql`
                mutation{
                     ClearPayout(amount:${amount},description:"${description}",panel_user_id:${id})
                }
          `
        }).then(result => {
            console.log(result)
            return result.data.ClearPayout
        }).catch(error => {
            console.log(error)
        });


    }
}

const updateUserBankInfo = (client, data) => {

    return dispatch => {
        return client.mutate({
            mutation: gql`
                mutation bank {
                  UpdatePanelUserBankInfo(
                    bank_card_number:"${data.get("bank_card_number")}"
                    IBAN: "${data.get("IBAN")}"
                    Currency: "${data.get("Currency")}"
                    swift_code: "${data.get("swift_code")}"
                    holder_name: "${data.get("holder_name")}"
                    bank_info: "${data.get("bank_info")}"
                  ) {
                    User {
                      first_name
                      last_name
                      email
                      bank_info {
                        IBAN
                        Currency
                        swift_code
                        holder_name
                        bank_info
                      }
                      contact_info {
                        phone
                        mobile
                        country
                        state
                        city
                        address
                        postal_code
                      }
                    }
                    message
                  }
                }

                 `
        }).then(res => {
            return res.data.UpdatePanelUserBankInfo


        }).catch(error => {


            console.log(error)
        })

    }
};

export default {
    GetWallet,
    updateUserBankInfo,
    GetLogs,
    updateContactInfo,
    GetUserDetails,
    GetUserWallet,
    ClearPayout,
    RequestExchange,
    RequestPayout,
}