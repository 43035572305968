import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import PhotoOutlined from "@material-ui/icons/PhotoOutlined";
import ContactMailOutlined from "@material-ui/icons/ContactMailOutlined";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import avatar from "assets/img/default-avatar.png"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import UserAction from "actions/User/UserAction";
import Constants from "Utils/constatns.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {apiUrl} from "../../config";

const useStyles = makeStyles(styles);

function UserInfo(props) {
    const [alert, setAlert] = React.useState(null);
    const {dispatch, client} = props;

    const submitContactInfo = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target)
        dispatch(UserAction.updateContactInfo(client, fd)).then(res => {
            ShowAlert()
        })
    };
    const SubmitImages = (e) => {
        e.preventDefault();
        const frmUpload = document.getElementById('frmUpload')

        let formData = new FormData(e.target);
        console.log(formData)

        let token = localStorage.getItem('access_token');
        fetch(apiUrl + 'api/panel-user/personal-photos', {
            method: 'POST', body: formData, headers: {
                authorization: token ? `Bearer ${token}` : null
            }
        }).then(res => {
            console.log(res)
            if (res.ok) {
                ShowAlert()
                frmUpload.reset()
            }
        }).catch(err => {
            console.log(err)
        })
    };
    const submitBankInfo = (e) => {
        e.preventDefault();
        let fd = new FormData(e.target)
        dispatch(UserAction.updateUserBankInfo(client, fd)).then(res => {
            ShowAlert()
        })
    };
    const ShowAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Successful"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                saved Successfully
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };

    const data = props.user_details;


    const classes = useStyles();

    return (
        <GridContainer>
            {alert}
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PersonOutline/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Personal Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        First Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="first_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disabled: true,
                                            name: "first_name",
                                            value: data && data.first_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Last Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="last_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disabled: true,
                                            name: "last_name",
                                            value: data && data.last_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Identity Code | Passport No.
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="identity"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disabled: true,
                                            name: "identity",
                                            value: data && data.identity
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Email Address
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            type: "email",
                                            name: "email",
                                            value: data && data.email
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Commission Percent %
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        disable
                                        id="commission"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            disabled: true,
                                            step: "any",
                                            name: "percent",
                                            value: data && data.percent
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>


                        </form>
                    </CardBody>
                </Card>
            </GridItem><GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <PhotoOutlined/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Upload Images</h4>
                </CardHeader>
                <CardBody>
                    <form onSubmit={e => SubmitImages(e)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <legend>Personal Photo</legend>
                                <ImageUpload
                                    name={"photo"}
                                    avatar
                                    addButtonProps={{
                                        color: "rose",
                                        round: true
                                    }}
                                    changeButtonProps={{
                                        color: "rose",
                                        round: true
                                    }}
                                    removeButtonProps={{
                                        color: "danger",
                                        round: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <img width="100%" src={apiUrl + data.user_profile} onError={avatar}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <legend>
                                    Identity Card or Passport
                                </legend>
                                <ImageUpload
                                    name={"identity"}
                                    addButtonProps={{
                                        color: "rose",
                                        round: true
                                    }}
                                    changeButtonProps={{
                                        color: "rose",
                                        round: true
                                    }}
                                    removeButtonProps={{
                                        color: "danger",
                                        round: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <img width="100%" src={apiUrl + data.identity_photo} onError={avatar}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button type={"submit"} color="rose">Submit</Button>

                            </GridItem>

                        </GridContainer>

                    </form>
                </CardBody>
            </Card>
        </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <ContactMailOutlined/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Contact Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => submitContactInfo(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Mobile</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="mobile"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            name: "mobile",
                                            defaultValue: data.contact_info && data.contact_info.mobile
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Phone</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            name: "phone",
                                            defaultValue: data.contact_info && data.contact_info.phone
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Country</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="country"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "country",
                                            defaultValue: data && data.contact_info && data.contact_info.country
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>State/Region</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="state"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "state",
                                            defaultValue: data && data.contact_info && data.contact_info.state
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>City</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="city"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "city",
                                            defaultValue: data && data.contact_info && data.contact_info.city
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Address</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "address",
                                            defaultValue: data && data.contact_info && data.contact_info.address
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Postal Code</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="postal_code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "postal_code",
                                            defaultValue: data && data.contact_info && data.contact_info.postal_code
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <Button type={"submit"} color="rose">Submit</Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <AccountBalanceOutlined/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Bank Information</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={e => submitBankInfo(e)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>IBAN (SHABA)</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="IBAN"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "IBAN",
                                            defaultValue: data.bank_info && data.bank_info.IBAN
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Currency</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="Currency"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "Currency",
                                            defaultValue: data && data.bank_info && data.bank_info.Currency
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Swift Code</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="swift_code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "swift_code",
                                            defaultValue: data && data.bank_info && data.bank_info.swift_code
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Holder Name</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="holder_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "holder_name",
                                            defaultValue: data && data.bank_info && data.bank_info.holder_name
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Bank Card number</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="bank_card_number"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "bank_card_number",
                                            defaultValue: data && data.bank_info && data.bank_info.bank_card_number
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>Bank Info</FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        id="bank_info"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            name: "bank_info",
                                            defaultValue: data && data.bank_info && data.bank_info.bank_info
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>


                            <Button type={"submit"} color="rose">Submit</Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>


        </GridContainer>
    );
}

const mapStateToProps = state => ({
    error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
    UserAction: bindActionCreators({UserAction}, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo)