export const prefix = "hotel/";
export const GET_TRANSACTIONS_REQUEST = prefix + 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = prefix + 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED  = prefix + 'GET_TRANSACTIONS_FAILED';


export const GET_PAYOUT_REQUEST = prefix + 'GET_PAYOUT_REQUEST';
export const GET_PAYOUT_SUCCESS = prefix + 'GET_PAYOUT_SUCCESS';
export const GET_PAYOUT_FAILED  = prefix + 'GET_PAYOUT_FAILED';




